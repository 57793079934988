
export class Notification  {
  public id: number = null;
  public content_id: number = null;
  public event_id: number = null;
  public date_notification: string = null;
  public title: string = null;
  public url: string = null;
  public short_description: string = null;
  public showDescription:boolean = false;
  public created_at:string = null;
  public unread:boolean = true;
  
  constructor(id?: number | Object ) {
    if (typeof id == 'object') {
      this.import(id);
    } else {
      this.id = id as number | null;
    }
  }

  exists() {
    return !!this.id;
  }

  import(data: any) {
    for (var key in data) {
      if (this.hasOwnProperty(key)) {
        switch (key) {
          
          default:
            this[key as keyof this] = data[key];
        }
      }
    }
  }


}
