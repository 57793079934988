import { ElementRef, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  background_audio: HTMLAudioElement = null;
  audio_file = '/assets/quizz/sounds/forest.mp3';

  constructor() {}

  setAudioFile(start = false) {
    this.pauseBackgroundAudio();
    this.background_audio.src= this.audio_file;
    if (start) {
      this.startBackgroundAudio();
    }
  }

  setBackgroundAudioElement(el: HTMLAudioElement, start = false) {
    console.log('setBackgroundAudioElement', el)
    this.background_audio = el;
    this.setAudioFile();

    if (start && !this.isPaused() ) {
      this.startBackgroundAudio();
    }
  }

  startBackgroundAudio() {
    if (this.background_audio) {
      this.setAudioFile();
      
      this.background_audio.volume = 0.2;
      this.background_audio.play();
    }
  }

  pauseBackgroundAudio() {
    if (this.background_audio) {

      this.background_audio.pause();
    }
  }

  resumeBackgroundAudio() {
    if (this.background_audio && !this.isPaused()) {
      this.background_audio.play();
    }
  }

  toggleBackgroundAudio() {
    if (this.background_audio) {
      if (!this.background_audio.paused) {
        this.pause(true);
        this.background_audio.pause();
      } else {
        this.pause(false);
        this.background_audio.play();
      }
    }
  }

  isPaused() {
    return localStorage.getItem('rs_audio')=='1';
  }

  pause(val:boolean) {
    localStorage.setItem('rs_audio', val?'1':'0');
  }
  
}
