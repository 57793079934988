import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cms-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  @Input() anim: string = 'spin';
  @Input() img: string = null;
  @Input() icon: string = 'bubbles'; // "bubbles" ｜ "circles" ｜ "circular" ｜ "crescent" ｜ "dots" ｜ "lines" ｜ "lines-sharp" ｜ "lines-sharp-small" ｜ "lines-small"
  @Input() color: string = 'primary';
  @Input() size: string = 'medium';

  constructor() {}

  ngOnInit() {}
}
