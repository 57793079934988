import { Component, Input, OnInit } from '@angular/core';
import { IonicSlides, ModalController } from '@ionic/angular';
import { CmsService } from 'src/app/services/cms.service';
import { environment } from 'src/environments/environment';
import { GalleryViewerComponent } from '../../gallery-viewer/gallery-viewer.component';

@Component({
  selector: 'cms-block-gallery',
  templateUrl: './gallery-block.component.html',
  styleUrls: ['./gallery-block.component.scss'],
})
export class GalleryBlockComponent implements OnInit {
  public ready: boolean = false;
  public content_id: string = null;
  public img_path: string = environment.fileserver.images;
  public slideOpts = {
    initialSlide: 0,
    slidesPerView: 1.2,
    //centeredSlides:true,
    spaceBetween: 10,
    speed: 400,
  };
  public swiperModules = [IonicSlides];

  @Input() block = null;

  constructor(
    public cms: CmsService,
    protected modalController: ModalController
  ) {}

  ngOnInit() {}

  async fullscreenImage(idx) {
    console.log(event);

    //const thumbnail = event.srcElement as HTMLImageElement;
    console.log('THUMB', idx)

    //const gallery = [{ url: thumbnail.src, landscape: true }];

    
    // const imgs = this.block.data.images;
    // let before=[];
    // let found = false;

    // this.block.data.images.forEach( (img) => {
    //   img = this.img_path+'/'+img;
    //   if (img==thumbnail.src || found) {
    //     found = true;
    //     gallery.push({ url: img, landscape: true });
    //   } else {
    //     before.push({ url: img, landscape: true });
    //   }
    // });
    // before.forEach((img)=>{
    //   gallery.push(img);
    // })

    const modal = await this.modalController.create({
      component: GalleryViewerComponent,
      componentProps: {
        imgTitle: '',
        imgDescription: '',
        gallery: this.block.data.media,
        imgIdx:idx,
        showMediaName:this.block.data.show_media_name,
        showMediaCaption:this.block.data.show_media_caption,
        showMediaDescription:this.block.data.show_media_description,
        
      },
      cssClass: 'modal-fullscreen',
      keyboardClose: true,
      showBackdrop: true,
    });

    return await modal.present();
  }
}
