import * as _ from 'lodash';
import * as moment from 'moment';

import { UserGame } from './UserGame';
import { Avatar } from './Avatar';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DataService } from 'src/app/services/data.service';
import { BehaviorSubject, Observable } from 'rxjs';

export class Player {
  userGame: UserGame = null;
  defaultAvatar: Avatar = null;
  defaultDifficulty: string = null;
  gamesOver: any[] = null;
  level_count: number = null;

  private _authService: AuthenticationService;
  private _dataService: DataService;

  // current game
  private _currentGameSubject: BehaviorSubject<UserGame> =
    new BehaviorSubject<UserGame>(null);
  private _currentGame$: Observable<UserGame> =
    this._currentGameSubject.asObservable();

  constructor(data: Object | null = null) {
    if (data) {
      this.import(data);
    }
    if (!this.defaultAvatar) {
      this.defaultAvatar = new Avatar({ id: 1, code: 'bear' });
    }
    if (!this.userGame) {
      this.userGame = new UserGame({
        avatar: this.defaultAvatar,
        avatar_id: this.defaultAvatar.id,
        level: 0,
      });
    }
  }

  import(data: any) {
    for (var key in data) {
      switch (key) {
        case 'userGame':
          this[key] = new UserGame(data[key]);
          break;
        default:
          let self = this;
          self[key as keyof this] = data[key];
      }
    }
  }

  static factory(auth, data): Player | null {
    const p = new this();
    p.setServices(auth, data);
    return p;
  }

  gameObservable() {
    return this._currentGame$;
  }

  setServices(auth, data) {
    this._authService = auth;
    this._dataService = data;
  }

  load(userGame: UserGame = null) {
    const json = localStorage.getItem('RSplayer');
    if (json) {
      this.import(JSON.parse(json));
    }

    if (userGame) {
      this.userGame = userGame;
      this.defaultAvatar = userGame.avatar;
      this.defaultDifficulty = userGame.difficulty;
    }

    this._currentGameSubject.next(this.userGame);
  }

  isUserLogged() {
    return this._authService && this._authService.getCurrentUser() && this._authService.getCurrentUser().exists();
  }

  saveLocal() {
    localStorage.setItem(
      'RSplayer',
      JSON.stringify(_.omitBy(this, (value, key) => key.startsWith('_')))
    );
  }

  save() {
    if (this.isUserLogged() && this.userGame.game_id) {
      if (this.userGame.id) {
        this._dataService
          .putAsPromise(`rs/usergame/${this.userGame.id}`, this.userGame)
          .then((res) => {
            if (res && res.data) {
              // update level_count, avatar, difficulty
              this.userGame = new UserGame(res.data);

              this.level_count = res.data.level_count;

              this.saveLocal();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this._dataService
          .postAsPromise(`rs/usergame`, this.userGame)
          .then((res) => {
            if (res && res.data) {
              // update level_count, avatar, difficulty
              this.userGame = new UserGame(res.data);
              this.level_count = res.data.level_count;
              this.saveLocal();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      this.saveLocal();
    }
  }

  isAnonymousGame() {
    return !this.userGame.id && !this.userGame.user_id;
  }

  setAvatar(avatar: Avatar) {
    this.defaultAvatar = avatar;
  }

  getAvatarCode() {
    return this.userGame.avatar.code;
  }

  setDifficulty(difficulty) {
    this.defaultDifficulty = difficulty;
    this.userGame.difficulty = difficulty;
    this.save();
  }

  setGameCategory(category) {
    this.userGame.category = category;
    this.userGame.category_id = category.id;
  }

  setGameMode(mode) {
    if (this.userGame.game_id && this.userGame.game_id != mode.id) {
      this.userGame.category = null;
      this.userGame.category_id = null;
    }
    this.userGame.game = mode;
    this.userGame.game_id = mode.id;
  }

  setUserGame(game: UserGame) {
    if (!game.level) {
      game.level = 0;
    }
    this.userGame = game;
    this._currentGameSubject.next(this.userGame);
    this.save();
  }

  resetGame() {
    this.userGame.id = null;
    this.userGame.level = 0;
    this.userGame.lives = 3;
    this._currentGameSubject.next(this.userGame);
  }

  canStartGame(): boolean {
    return (
      this.userGame &&
      this.userGame.avatar_id !== null &&
      this.userGame.difficulty !== null &&
      this.userGame.game_id !== null &&
      this.userGame.category_id !== null
    );
  }

  getGameCode() {
    return this.userGame && this.userGame.game
      ? this.userGame.game['code']
      : null;
  }

  getGameLevel() {
    return this.userGame.level;
  }

  getDifficulty() {
    return this.defaultDifficulty;
  }

  findNextLevel() {
    for(let i=(this.userGame.level || 0)+1; i<5; i++) {
      // the next level is the next one with 0 answer or with wrong answer
      const l = this.userGame.levels[i];
      if ( (l.right + l.wrong) == 0 || l.wrong>0 ) {
        return i;
      }
    }
    return 5;
  }

  nextLevel() {
    this.userGame.level = this.findNextLevel();
    this.userGame.max_level = Math.max(
      this.userGame.max_level,
      this.userGame.level
    );
  }

  earnLife() {
    this.userGame.lives++;
  }

  loseLife() {
    this.userGame.lives--;
  }

  getLives() {
    return this.userGame.lives || 0;
  }

  setLives(lives) {
    this.userGame.lives = lives;
    this._currentGameSubject.next(this.userGame);

    this.save();
  }

  getLevel() {
    return this.userGame.level;
  }

  setLevel(level) {
    this.userGame.level = level;
    this.save();
  }

  resetGamesOver() {
    this.gamesOver = null;
    this.save();
  }

  removeGameOver(game = null) {
    if (!game) {
      game = this.userGame;
    }
    if (this.gamesOver) {
      let index = _.findIndex(this.gamesOver, {
        game_id: game.game_id,
        category_id: game.category_id,
      });
      if (index !== -1) {
        this.gamesOver.splice(index, 1);
      }
    }
    this.save();
  }

  rememberGameOver(game: UserGame = null) {
    if (!game) {
      game = this.userGame;
    }
    game.updated_at = moment().format('Y-MM-DD');

    if (this.gamesOver) {
      let o = _.find(this.gamesOver, {
        game_id: game.game_id,
        category_id: game.category_id,
      });
      if (!o) {
        this.gamesOver.push(game);
      }
    } else {
      this.gamesOver = [game];
    }
    this.save();
  }

  newGame(game, category) {
    this.userGame = new UserGame({
      avatar: this.defaultAvatar,
      avatar_id: this.defaultAvatar.id,
      difficulty: this.defaultDifficulty,
      level: 0,
      game: {
        id: game.id,
        code: game.code,
      },
      game_id: game.id,
      category: {
        id: category.id,
        code: category.code,
      },
      category_id: category.id,
      user_id: this._authService.getCurrentUser().id,
    });
    this.saveLocal();
    this._currentGameSubject.next(this.userGame);
  }


  deserveBigBadge() {
    console.log('deserveBigBadge', this.userGame.levels)
    for(let level of this.userGame.levels) {
      if (level.right!=0 && level.wrong!=0) {
        return false;
      } 
    }
    return true;
  }

  isLastLevel() {
    return this.getLevel() >= 5;
  }


}
