import { AuthenticationService } from '../services/authentication.service';
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import * as _ from 'lodash';

@Directive({ selector: '[hasRoles]' })
export class HasRolesDirective {
    private granted = false;

    @Input() set hasRoles(roles: Array<string>) {
        if (!roles || roles.length==0) {
            this.granted = true;
        } else {
            const authUser = this.auth.getCurrentAuthUser();
            if (authUser && authUser.exists()) {
                this.granted = _.intersection(authUser.roles, roles).length>0;
            } else {
                this.granted = false;
            }

        }
        if (this.granted) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
      }

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private auth : AuthenticationService) { }
}