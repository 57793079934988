import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage-angular';

export interface ListApi {
  data: any[];
  meta: any;
}

@Injectable({
  providedIn: 'root',
})
export class DataService {
  protected api_root = environment.api.root;
  protected downloads_root = environment.fileserver.downloads;
  protected data = { lists: {}, params: {} };
  private _storage: Storage | null = null;
  private cacheTimeout = environment.api['cache_timeout'];

  constructor(private http: HttpClient, private storage: Storage) {
  }

  async initStorage() {
    const storage = await this.storage.create();
    this._storage = storage;
  }

  getAsPromise(endpoint: string, params = {}, cached=false): any {
    return new Promise(async (resolve, reject) => {
      if (cached && this.cacheTimeout) {
        if (!this._storage) {
          // init the storage one time
          await this.initStorage();
        }
        const res = await this.getFromCache(endpoint, params);
        if (res) {
          resolve(res);
          return;
        }
      }

      this.http
        .get<any[]>(`${this.api_root}/${endpoint}`, params)
        .toPromise()
        .then((res) => {
          if (cached) {
            this.setInCache(endpoint, params, res);
          }
          resolve(res);
        })
        .catch((error) => {
          console.log('RES=', error);
          reject(error);
        });
    });
  
  }

  get(endpoint: string, params = {}): Observable<any> {
    return this.http.get(`${this.api_root}/${endpoint}`, { params: params });
  }

  async getFromCache(endpoint, params={}) {
    let d = await this._storage.get(endpoint);
    if (d) {
      console.log('CACHE.get', endpoint)
      const t= new Date().getTime();
      if (d.timeout>t) {
        return d.data;
      }
      console.log('CACHE.expired', endpoint)
      this._storage.remove(endpoint); 
    }
    console.log('CACHE.not_found', endpoint)
    return null;
  } 

  async setInCache(endpoint, params={}, data=null) {
    const d={
      endpoint:endpoint,
      params:params,
      data:data,
      timeout:this.cacheTimeout*1000 + new Date().getTime()
    }
    await this._storage.set(endpoint, d);
    console.log('CACHE.set', endpoint)
  }



  getList(endpoint: string, params = {}): Observable<ListApi> {
    return this.http.get<ListApi>(`${this.api_root}/${endpoint}`, {
      params: params,
    });
  }

  delete(endpoint: string, params = {}): Observable<any> {
    return this.http.delete(`${this.api_root}/${endpoint}`, { params: params });
  }

  putAsPromise(endpoint: string, data: {}): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(`${this.api_root}/${endpoint}`, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.log('RES=', error);
          reject(this.parseErrors(error));
        });
    });
  }

  postAsPromise(endpoint: string, data: {}): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.api_root}/${endpoint}`, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.log('RES=', error);
          reject(this.parseErrors(error));
        });
    });
  }

  deleteAsPromise(endpoint: string, data: {} | null = null): Promise<any> {
    if (!data) {
      data = {};
    }
    let p = { params: data };
    return new Promise((resolve, reject) => {
      this.http
        .delete(`${this.api_root}/${endpoint}`, p)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(this.parseErrors(error));
        });
    });
  }

  getExcelFile(endpoint: string, filter: {} | null = null, filename = null) {
    return new Promise((resolve, reject) => {
      let p: any = {
        params: { filter: filter ? JSON.stringify(filter) : null },
        headers: new HttpHeaders({
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        responseType: 'blob',
      };

      this.http
        .get(`${this.api_root}/${endpoint}`, p)
        .toPromise()
        .then((data) => {
          const file = new Blob([data as BlobPart], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const fileURL = window.URL.createObjectURL(file);
          const element = document.createElement('a');
          element.href = URL.createObjectURL(file);
          if (filename) {
            element.download = filename;
            element.click();
          } else {
            window.open(fileURL);
          }
          resolve(true);
        })
        .catch((error) => {
          reject(this.parseErrors(error));
        });
    });
  }

  getFileFromEvent(event: any) {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.downloads_root}/${event.uri}`, {
          headers: new HttpHeaders({
            'Content-Type': event.mime,
          }),
          responseType: 'blob',
        })
        .toPromise()
        .then((data) => {
          const file = new Blob([data as BlobPart], { type: event.mime });
          const fileURL = window.URL.createObjectURL(file);
          const element = document.createElement('a');
          element.href = URL.createObjectURL(file);
          if (event.filename) {
            element.download = event.filename;
            element.click();
          } else {
            window.open(fileURL);
          }
          resolve(true);
        })
        .catch((error) => {
          console.log('excel was not downloaded!!!!!');
          reject(this.parseErrors(error));
        });
    });
  }

  parseErrors(data: any) {
    let ret = data.error;
    ret.fields = {};
    if (data.error.errors && data.error.errors.length > 0) {
      data.error.errors.forEach((err: any) => {
        ret.fields[err.field] = err.code;
      });
    }
    return ret;
  }
}
