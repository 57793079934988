// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  pwa: false,
  api: {
    root: "https://rsquizz-api.staging.romaniasalbatica.ro/api",
    cache_timeout:300
  },
  fileserver: {
    downloads: "https://rsquizz-api.staging.romaniasalbatica.ro/api/downloads",
    images: "https://rsquizz-api.staging.romaniasalbatica.ro/uploads"
  },
  cache_folder : "cmscache",
  auth: {
    providers: {
      google: { enabled: false }, // sign-in and register
      facebook: { enabled: false }, // sign-in and register
      apple: {
        enable: true,
        options: {
          clientId:"ro.romaniasalbatica.app",
          redirectURI: 'https://rsquizz-api.staging.romaniasalbatica.ro/api/auth/apple',
          scopes:'email name'
        }
        
      },
      email: {
        enabled: true,
        register: true,
        forgot: false,
        fields: {
          displayName: true,
          lastname: true,
          firstname: true,
          phone: true
        }
      },
      phone: { enabled: false }
    },
    roles: {
      whitelist: null,
      blacklist: null
    }
  },
  rs:{
    debug_questions: true
  },
  firebase: {
    apiKey: "AIzaSyA5twpdtHyu7nukYzOd8nIAXtXBtFhjU4U",
    authDomain: "romania-salbatica-app.firebaseapp.com",
    projectId: "romania-salbatica-app",
    storageBucket: "romania-salbatica-app.appspot.com",
    messagingSenderId: "797213619358",
    appId: "1:797213619358:web:f095577f62ba60a820b78f",
    measurementId: "G-621EFJ81QT"
  },
  analytics: {
    enabled:true
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
