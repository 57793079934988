<ion-content class="cms-background" >
  <ion-fab class="cms-fab" slot="fixed" vertical="top" horizontal="start">
    <ion-fab-button (click)="cms.showSideMenu()">
      <ion-icon name="menu-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <ion-fab class="cms-fab" slot="fixed" vertical="top" horizontal="end">
    <ion-fab-button (click)="cms.back()">
      <ion-icon name="chevron-back-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <div class="nav-wrapper" *ngIf="ready">
    <div class="grid">
      <div
        class="nav-item col-{{ header_item?.size }} {{
          header_item?.css
        }} first-navitem"
      >
        <cms-cached-image
          mode="div"
          class="cell-wrapper"
          [src]="nav_path + '/' + header_item.media_url"
          [class.hand]="header_item.url || header_item.content_id"
        >
          <div *ngIf="header_item?.loading" class="loader">
            <cms-loader size="small"></cms-loader>
          </div>

          <div class="nav-title">
            <!-- <span>{{ header_item.title }}</span> -->
            <ion-searchbar
              class="rs"
              [placeholder]="header_item.title"
              [debounce]="600"
              show-clear-button="always"
              (ionChange)="changeSearch($event)"
              (ionClear)="clearchSearch($event)"
            ></ion-searchbar>
          </div>
        </cms-cached-image>

        <cms-loader size="small" *ngIf="!nav_ready"></cms-loader>

        <div
          class="menu-search menu-block"
          *ngIf="menu_search && !search_results && !searching"
        >
          <cms-loader size="small" *ngIf="searching"></cms-loader>
          <ion-list>
            <ng-container *ngFor="let item of menu_search; let idx = index">
              <ion-item
                *ngIf="!item.is_separator"
                detail="false"
                (click)="onMenuClick(item)"
                class="hand"
                ><ion-label>{{ item.title }}</ion-label>
                <ion-icon
                  *ngIf="item.media_url"
                  [name]="item.media_url"
                  slot="end"
                ></ion-icon
              ></ion-item>

              <ng-container *ngIf="item.is_separator">
                <ion-item
                  *ngIf="!item.media_url"
                  detail="false"
                  class="separator"
                  [class.first-navitem]="idx === 0"
                  ><ion-label>{{ item.title }}</ion-label>
                </ion-item>
              </ng-container>
            </ng-container>
          </ion-list>
          <cms-page-footer src="/assets/images/footer.jpg"></cms-page-footer>


        </div>

        <ng-container *ngIf="search_results?.length > 0">
          <ng-container *ngFor="let item of search_results; let idx = index">
            <div
              class="nav-item col-{{ item.size }}"
              [class.marginTop]="item.margin_top || idx === 0"
              [class.marginBottom]="item.margin_bottom"
              [class.marginLeft]="item.margin_left"
              [class.marginRight]="item.margin_right"
            >
              <div
                class="cell-wrapper"
                [style.background-image]="
                  'url(' + nav_path + '/' + item.media_url + ')'
                "
                [class.hand]="item.url || item.content_id"
                (click)="clickOnItem($event, item)"
              >
                <div *ngIf="item?.loading" class="loader">
                  <ion-spinner
                    color="primary"
                    class="big-spinner"
                  ></ion-spinner>
                </div>

                <div class="nav-title">
                  <span>{{ item.title }}</span>
                </div>
              </div>
            </div>
          </ng-container>
          <cms-page-footer class="results" src="/assets/images/footer.jpg"></cms-page-footer>


        </ng-container>
        <ng-container *ngIf="search_term && search_results?.length === 0">
          <div class="nav-item col-12 no-result">
            {{ "SEARCH.NO_RESULT" | translate }}
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <octopus-menu *ngIf="!keyboard_visible"></octopus-menu>
</ion-content>
