<div class="wrapper__form">
  <div class="card-container">
      <div class="card-logo" *ngIf="showLogo">
        <img class="logo" src="/assets/images/logo_auth_white.svg" alt="logo">
      </div>

      <div *ngIf="providers['email']?.enabled && method == 'email'" class="card-content">
        <!-- LOGIN -->
        <form [class.animation]="!show_register" *ngIf="!show_register && !show_forgot && !show_2fa && !showLoginWithOTPForm"
        [formGroup]="loginWithEmailForm" novalidate (ngSubmit)="onLoginWithEmailSubmit($event, loginWithEmailForm.value)">
          <div class="text">
            <h1 class="title" *ngIf="title">{{ title }}</h1>
            <p [innerHTML]="'AUTH.LOGIN.SEND_OTP_INFO_TEXT' | translate "></p>
          </div>

          <ion-item >
            <!-- <ion-label position="floating"  >{{ 'AUTH.EMAIL' | translate }}</ion-label> -->
            <ion-input formControlName="email" type="email" email labelPlacement="stacked" [label]="'AUTH.EMAIL' | translate"></ion-input>
            <ion-note slot="error" *ngIf="loginWithEmailForm.controls['email'].touched && loginWithEmailForm.controls['email'].hasError('required')">{{ "VALIDATION.REQUIRED" | translate }}</ion-note>
            <ion-note slot="error" *ngIf="loginWithEmailForm.controls['email'].touched && loginWithEmailForm.controls['email'].hasError('email')">{{ "VALIDATION.EMAIL" | translate }}</ion-note>
          </ion-item>

          <div *ngIf="error_msg">
            <strong>{{ error_msg | translate }}</strong>
          </div>
          <div class="buttons">
            <div align="center">
              <ion-button color="primary" expand="block" type="submit" class="auth-button">
                <ion-icon name="mail" slot="start" [class.spin]="loader && method=='email'"></ion-icon>
                <div class="inner-bt">
                  <!-- <cms-loader
                  size="small"
                  
                  *ngIf="loader"
                ></cms-loader> -->
                  <span>{{ "AUTH.BT_SEND_OTP" | translate }}</span>
                </div>
              </ion-button>

              <ion-button color="primary" expand="block"  class="auth-button"
              *ngIf="platform==='ios'"
              (click)="signInAppleNative()">
              <ion-icon name="logo-apple" slot="start"></ion-icon>
                <div class="inner-bt">
                  <cms-loader
                  
                  size="small"
                  
                  *ngIf="loader"
                  
                ></cms-loader>
                  <span>{{ "AUTH.BT_SIGNIN_APPLE" | translate }}</span>
                </div>
              </ion-button> 

              <ion-button color="primary" expand="block"  class="auth-button"
              
              (click)="signInGoogle()">
              <ion-icon name="logo-google" slot="start" [class.spin]="loader && method=='google'"></ion-icon>
                <div class="inner-bt">
                  <!-- <cms-loader
                  
                  size="small"
                  
                  *ngIf="loader"
                  
                ></cms-loader> -->
                  <span>{{ "AUTH.BT_SIGNIN_GOOGLE" | translate }}</span>
                </div>
              </ion-button>

            </div>
            <div class="register-bts" *ngIf="(providers['email']?.register || providers['email']?.forgot) && showRegisterBtn">
              <ion-button   color="primary" expand="block" *ngIf="providers['email']?.register"
                (click)="goToRegister()" >
                {{ "AUTH.BT_REGISTER" | translate }}
              </ion-button>
              <ion-button fill="outline" *ngIf="providers['email']?.forgot" (click)="forgotPassword()">
                {{ "AUTH.BT_FORGOT_PASSWORD" | translate }}
              </ion-button>
            </div>

            <!-- <div class="ion-text-center ion-margin" *ngIf="showBackBtn">
              <span class="hand" (click)="goBackToPrevPage()">
                <ion-icon name="arrow-back-outline"></ion-icon> {{ 'BUTTONS.BT_BACK' | translate }}
              </span>
            </div> -->
          </div>
        </form>

        <!-- LOGIN WITH OTP-->
        <form [class.animation]="showLoginWithOTPForm" *ngIf="showLoginWithOTPForm"
        [formGroup]="loginWithOTPForm" novalidate (ngSubmit)="onLoginWithOTPSubmit($event)">
          <div class="text">
            <h1 class="title">{{ "AUTH.LOGIN.LOGIN_WITH_OTP_TITLE" | translate }}</h1>
            <p [innerHTML]="'AUTH.REGISTER.LOGIN_WITH_OTP_INFO_TEXT' | translate "></p>
          </div>
          <ion-item>
            <!-- <ion-label position="floating">{{ 'AUTH.OTP_CODE' | translate }}</ion-label> -->
            <ion-input formControlName="token" type="token" labelPlacement="stacked" [label]="'AUTH.OTP_CODE' | translate"></ion-input>
            <ion-note slot="error" *ngIf="loginWithOTPForm.controls['token'].touched && loginWithOTPForm.controls['token'].hasError('required')">{{ "VALIDATION.REQUIRED" | translate }}</ion-note>
          </ion-item>

          <div *ngIf="error_msg">
            <strong>{{ error_msg | translate }}</strong>
          </div>
          <div align="center" class="ion-margin">
            <ion-button fill="outline" type="submit">
              <div class="inner-bt">
                <cms-loader
                
                size="small"
                
                *ngIf="loader"
              ></cms-loader>
                <span>{{ "AUTH.BT_SIGNIN" | translate }}</span>
              </div>
            </ion-button>

            <ion-button  fill="outline" class="bt-back" type="button" (click)="goToLogin()" fill="clear">
              {{ "AUTH.REGISTER.BT_BACK_TO_SEND_OTP" | translate }}
            </ion-button>


          </div>
        </form>
      </div>

  </div>
</div>