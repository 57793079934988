<div class="wrapper__form">
  <div class="card-container">
    <div class="card-logo" *ngIf="showLogo">
      <img class="logo" src="/assets/images/logo_auth_white.svg" alt="logo">
    </div>

    <div *ngIf="providers['email']?.enabled && method == 'email'" class="card-content">
      <!-- REGISTER -->
      <form
        [class.animation]="show_register"
        *ngIf="
          providers['email']?.register && show_register && show_register_form
        "
        [formGroup]="registerWithEmailForm"
        novalidate
        (ngSubmit)="onRegisterWithEmailSubmit()"
      >
        <div class="text">
          <h1 class="title" *ngIf="title">{{ title }}</h1>
          <p [innerHTML]="'AUTH.REGISTER.SEND_INFO_TEXT' | translate"></p>
        </div>

        <ion-item>
          <ion-label position="floating">{{
            "AUTH.REGISTER.FIRSTNAME" | translate
          }}</ion-label>
          <ion-input formControlName="firstname" type="text"></ion-input>
          <ion-note
            slot="error"
            *ngIf="
              registerWithEmailForm.controls['firstname'].touched &&
              registerWithEmailForm.controls['firstname'].hasError('required')
            "
            >{{ "VALIDATION.REQUIRED" | translate }}</ion-note
          >
        </ion-item>

        <ion-item>
          <ion-label position="floating">{{
            "AUTH.REGISTER.LASTNAME" | translate
          }}</ion-label>
          <ion-input formControlName="lastname" type="text"></ion-input>
          <ion-note
            slot="error"
            *ngIf="
              registerWithEmailForm.controls['lastname'].touched &&
              registerWithEmailForm.controls['lastname'].hasError('required')
            "
            >{{ "VALIDATION.REQUIRED" | translate }}</ion-note
          >
        </ion-item>

        <ion-item>
          <ion-label position="floating">{{
            "AUTH.REGISTER.EMAIL" | translate
          }}</ion-label>
          <ion-input formControlName="email" type="email"></ion-input>
          <ion-note
            slot="error"
            *ngIf="
              registerWithEmailForm.controls['email'].touched &&
              registerWithEmailForm.controls['email'].hasError('required')
            "
            >{{ "VALIDATION.REQUIRED" | translate }}</ion-note
          >
          <ion-note
            slot="error"
            *ngIf="
              registerWithEmailForm.controls['email'].touched &&
              registerWithEmailForm.controls['email'].hasError('email')
            "
            >{{ "VALIDATION.EMAIL" | translate }}</ion-note
          >
          <ion-note
            slot="error"
            *ngIf="
              registerWithEmailForm.controls['email'].touched &&
              registerWithEmailForm.controls['email'].hasError('serverError')
            "
            >{{ getServerError("email") }}</ion-note
          >
        </ion-item>
        <div align="center" class="buttons">
          <ion-button color="primary" expand="block" type="submit">
            {{ "AUTH.REGISTER.BT_REGISTER" | translate }}
          </ion-button>

          <!-- <ion-button
            *ngIf="showLoginBtn"
            fill="outline"
            color="accent"
            class="bt-back"
            type="button"
            (click)="goToLogin()"
          >
            {{ "AUTH.REGISTER.BT_GO_TO_LOGIN" | translate }}
          </ion-button> -->
        </div>

        <!-- <div class="ion-text-center ion-margin" *ngIf="showBackBtn">
          <span class="hand" (click)="goBackToPrevPage()">
            <ion-icon name="arrow-back-outline"></ion-icon>
            {{ "BUTTONS.BT_BACK" | translate }}
          </span>
        </div> -->
      </form>

      <!-- SUCCSESS REGISTER -->
      <div *ngIf="!show_register_form">
        <div *ngIf="success_register_form" class="text">
          <h1 class="title">
            {{ "AUTH.REGISTER.SUCCSESS_TITLE" | translate }}
          </h1>
          <p [innerHTML]="'AUTH.REGISTER.SUCCSESS_TEXT' | translate"></p>
        </div>
        <div *ngIf="!success_register_form && error_msg" class="error_validate">
          <div>
            <strong>{{ error_msg | translate }}</strong>
          </div>
        </div>

        <!-- LOGIN WITH OTP-->
        <form
          [class.animation]="success_register_form"
          *ngIf="success_register_form"
          [formGroup]="loginWithOTPForm"
          novalidate
          (ngSubmit)="onLoginWithOTPSubmit($event)"
        >
          <ion-item>
            <ion-label position="floating">{{
              "AUTH.OTP_CODE" | translate
            }}</ion-label>
            <ion-input formControlName="token" type="token"></ion-input>
            <ion-note
              slot="error"
              *ngIf="
                loginWithOTPForm.controls['token'].touched &&
                loginWithOTPForm.controls['token'].hasError('required')
              "
              >{{ "VALIDATION.REQUIRED" | translate }}</ion-note
            >
          </ion-item>

          <div *ngIf="error_msg">
            <strong>{{ error_msg | translate }}</strong>
          </div>
          <div align="center" class="ion-margin">
            <ion-button fill="outline" type="submit">
              <div class="inner-bt">
                <cms-loader
                  
                  size="small"
                  
                  *ngIf="!loader"
                ></cms-loader>
                <span>{{ "AUTH.BT_SIGNIN" | translate }}</span>
              </div>
            </ion-button>
          </div>
        </form>

        <div align="center">
          <ion-button
            fill="outline"
            class="bt-back"
            type="button"
            (click)="goToLogin()"
            fill="clear"
          >
            {{ "AUTH.REGISTER.BT_BACK_TO_SEND_OTP" | translate }}
          </ion-button>
        </div>
      </div>
    </div>

  </div>
</div>
