import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { NavPageComponent } from './pages/nav/nav-page.component';
import { ContentPageComponent } from './pages/content/content-page.component';
import { EventsPageComponent } from './pages/events/events-page.component';
import { SearchPageComponent } from './pages/search/search-page.component';
import { NotificationsPageComponent } from './pages/notifications/notifications-page.component';

const routes: Routes = [
  {
    path: '',
    //redirectTo: '',
    component: NavPageComponent,
    data: { title: 'STATIC.NAV.HOMEPAGE' },
  },
  {
    path: 'login',
    redirectTo: 'auth/login',
  },
  {
    path: 'register',
    redirectTo: 'auth/register',
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((mod) => mod.AuthModule),
  },

  {
    path: 'quizz',
    loadChildren: () =>
      import('./modules/quizz/quizz.module').then((m) => m.QuizzModule),
  },
  {
    path: 'search',
    component: SearchPageComponent,
    data: {},
  },
   {
    path: 'nav/:menu_id',
    component: NavPageComponent,
    data: {},
  },
  {
    path: 'event/:event_id',
    component: ContentPageComponent,
    data: {},
  },
  {
    path: 'events',
    component: EventsPageComponent,
    data: {},
  },
  {
    path: 'notifications',
    component: NotificationsPageComponent,
    data: {},
  },
  {
    path: 'content/:content_id',
    component: ContentPageComponent,
    data: {},
  },
  { path: '**', component: ContentPageComponent, data: {} },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
