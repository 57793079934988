import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Calendar } from '@ionic-native/calendar/ngx';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { CmsService } from 'src/app/services/cms.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-content-page',
  templateUrl: './content-page.component.html',
  styleUrls: ['./content-page.component.scss'],
})
export class ContentPageComponent implements OnInit {
  public ready: boolean = false;
  public content_id: string = null;
  public event_id: string = null;
  public date_from: string = null;
  public date_to: string = null;
  public date_format: string = 'date';
  public locale: string = 'en';

  public img_path: string = environment.fileserver.images;

  public content: any = null;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    public cms: CmsService,
    private calendar: Calendar,
    private analytics: AnalyticsService
  ) {
    this.locale = this.translate.currentLang;

    this.route.paramMap.subscribe((params) => {
      this.content_id = params.get('content_id');
      this.event_id = params.get('event_id');

      this.ready = false;
      if (this.event_id) {
        this.cms.getEvent(this.event_id).then((content: any) => {
          if (content) {
            this.content = content;
            this.processEventDates(content);
            this.analytics.setScreenName(content.type, this.content.title);
          }
          this.ready = true;
        });
      } else {
        if (!this.content_id) {
          // search for a permalink
          console.log('Permalink ?', this.router.url);
          this.content_id = this.router.url;
        }
        this.cms.getContent(this.content_id).then((content: any) => {
          if (content) {
            this.content = content;

            if (content.type === 'event') {
              this.processEventDates(content);
            }
            this.analytics.setScreenName(content.type, this.content.title);
          }
          this.ready = true;
        });
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {}

  ionViewDidEnter() {}

  goToMenu() {}

  processEventDates(event) {
    const from = moment(event.date_from);
    const to = moment(event.date_to);

    const from_fullday = from.format('HH:mm:ss') == '00:00:00';
    const to_fullday = to.format('HH:mm:ss') == '00:00:00';

    if (from_fullday) {
      // full days
      this.date_from = from.format('YYYY-MM-DD');

      if (!from.isSame(to, 'day')) {
        // several days
        this.date_to = to.format('YYYY-MM-DD');
      }
    } else {
      // date time
      this.date_from = event.date_from;
      this.date_format = 'datetime';
      if (!from.isSame(to, 'day')) {
        this.date_to = event.date_to;
      }
    }
  }

  addToAgenda() {
    console.log('addToAgenda()');
    const event: any = {
      title: this.content.title,
      startDate: this.date_from,
      endDate: this.date_to,
    };
    if (this.content.location) {
      event.location = this.content.location;
    }

    this.calendar
      .createEventInteractively(
        this.content.title,
        this.content.location || '',
        '',
        moment(this.date_from).toDate(),
        this.date_to ? moment(this.date_to).toDate() : null
      )
      .then(() => {
        console.log('Event added to the calendar');
      })
      .catch((error) => {
        console.error('Error adding event to the calendar:', error);
      });
  }

  clickCTA() {
    if (this.content.cta_url) {
      if (this.content.cta_url.indexOf('http') === 0) {
        this.cms.openLink(this.content.cta_url);
      } else {
        this.cms.goTo(this.content.cta_url);
      }
    }
  }
}
