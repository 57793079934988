import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DataService } from 'src/app/services/data.service';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  public loader = false;
  public show_2fa = false;
  public show_register_form: boolean = true;
  public success_register_form: boolean = null;
  public show_forgot: boolean = false;
  public show_email_field: boolean = true;
  public show_button_send: boolean = true;
  public send_email = null;

  protected server_errors = null;

  loginWithOTPForm = this.fb.group({
    token: new UntypedFormControl(
      '',
      Validators.compose([Validators.required])
    ),
  });

  registerWithEmailForm = null;

  login2FAForm = this.fb.group({
    authcode: new UntypedFormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6),
      ])
    ),
  });

  method = null;
  captcha_valid = false;
  providers = {};
  show_providers = false;
  show_register = true;
  error_msg = null;
  isProd = false;
  next_url = null;
  loginMode: string = null;

  @Input() showBackBtn: boolean = true;
  @Input() set extraParams(p) {
    this._extraParameters = p;
  }
  private _extraParameters = {};
  @Input() withRouteChange: boolean = true;
  @Input() showLogo: boolean = true;
  @Input() title: String = null;
  @Input() showLoginBtn: boolean = true;
  @Output() onGoToLogin: EventEmitter<any> = new EventEmitter();
  @Output() afterLogin: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: UntypedFormBuilder,
    public auth: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private data: DataService,
    private location: Location,
    private analytics: AnalyticsService
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params && params['next_url']) {
        this.next_url = params['next_url'];
      }
    });

    this.route.paramMap.subscribe((params) => {
      const mode = params.get('mode');
      if (mode) {
        this.loginMode = mode;
      } else {
        this.loginMode = null;
      }
    });

    this.registerWithEmailForm = new UntypedFormGroup({
      firstname: new UntypedFormControl('', [Validators.required]),
      lastname: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl(
        '',
        Validators.compose([Validators.required, Validators.email])
      ),
    });

    this.isProd = environment.production;

    if (environment['auth'] && environment['auth'].providers) {
      for (let p in environment['auth'].providers) {
        if (environment['auth'].providers[p].enabled) {
          this.providers[p] = environment['auth'].providers[p];
        }
      }
    } else {
      this.providers = { email: { enabled: true } };
    }

    let keys = Object.keys(this.providers);
    this.show_providers =
      keys.indexOf('google') >= 0 ||
      keys.indexOf('facebook') >= 0 ||
      keys.length > 1;
    if (keys.length === 1 && keys[0] != 'google' && keys[0] != 'facebook') {
      this.method = keys[0];
    }
  }

  ngOnInit() {
    if (this.withRouteChange) {
      this.changeRoute();
    }
  }

  changeRoute() {
    if (this.loginMode == 'usergame') {
      this.next_url = '/quizz';
    }
    if (this.auth.authenticated) {
      if (this.next_url) {
        this.router.navigateByUrl(this.next_url);
      } else {
        this.router.navigate(['/']);
      }
    }
  }

  public onLoginWithOTPSubmit(event) {
    this.error_msg = null;
    this.loginWithOTPForm.markAllAsTouched();
    if (!this.loginWithOTPForm.valid) {
      return null;
    }

    this.loader = true;
    let data = this.loginWithOTPForm.value;
    this.auth
      .loginWithOtp(data.token)
      .then((res) => {
        this.afterLogin.emit({ success: true, params: this.registerParams });
        this.loader = false;
        // go to page
        if (this.withRouteChange) {
          this.changeRoute();
        }
      })
      .catch((error) => {
        this.loader = false;
        if (error.error == '2fa_enabled') {
          this.show_2fa = true;
          this.error_msg = null;
        } else {
          this.error_msg = this.translate.instant(
            'STATIC.ERRORS.' + error.error
          );
        }
        this.afterLogin.emit({ success: false, params: this.registerParams });
      });
  }

  public registerParams = {};
  public onRegisterWithEmailSubmit() {
    this.registerWithEmailForm.markAllAsTouched();
    let credentials = this.registerWithEmailForm.value;

    // tempPlayer
    if (this.loginMode == 'usergame') {
      const tempPlayer = this.auth.getTempPlayer();
      if (tempPlayer && tempPlayer.userGame) {
        credentials['user_game'] = tempPlayer.userGame;
      }
    }

    credentials = { ...credentials, ...this._extraParameters };

    if (this.registerWithEmailForm.valid) {
      this.loader = true;
      this.registerParams = credentials;

      this.auth
        .registerWithEmail(credentials)
        .then((res) => {
          this.success_register_form = true;
          this.show_register_form = false;
          this.loader = false;
          this.analytics.logEvent('sign_up', { method: 'otp' });
        })
        .catch((error) => {
          console.log('REGISTER NOK', error);
          this.setServerErrors(error);
          this.loader = false;
        });
    }
  }

  goToLogin() {
    this.onGoToLogin.emit(true);
  }

  setServerErrors(error) {
    this.server_errors = error;
    for (let i in error.errors) {
      this.registerWithEmailForm.controls[i].setErrors({ serverError: true });
    }
  }

  getServerError(controlName: string) {
    if (
      this.server_errors &&
      this.server_errors.errors &&
      this.server_errors.errors[controlName]
    ) {
      return this.translate.instant(
        'STATIC.ERRORS.' +
          this.server_errors.errors[controlName].toString().replace(/\./gi, '')
      );
    }
    return null;
  }

  goBackToPrevPage(): void {
    this.location.back();
  }
}
