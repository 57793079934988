import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CmsService } from 'src/app/services/cms.service';
import { environment } from 'src/environments/environment';
import { GalleryViewerComponent } from '../../gallery-viewer/gallery-viewer.component';

@Component({
  selector: 'cms-block-image',
  templateUrl: './image-block.component.html',
  styleUrls: ['./image-block.component.scss'],
})
export class ImageBlockComponent implements OnInit {
  public ready: boolean = false;
  public content_id: string = null;
  public img_path: string = environment.fileserver.images;

  @Input() block = null;

  constructor(
    public cms: CmsService,
    private modalController: ModalController
  ) {}

  ngOnInit() {}

  async fullscreenImage(event) {
    console.log(event);

    const thumbnail = event.srcElement as HTMLImageElement;

    const gallery = [{ url: thumbnail.src, landscape: true }];

    const modal = await this.modalController.create({
      component: GalleryViewerComponent,
      componentProps: {
        //imgSource: thumbnail.src,
        imgTitle: '',
        imgDescription: '',
        gallery: gallery,
        // imgIdx:idx
      },
      cssClass: 'modal-fullscreen',
      keyboardClose: true,
      showBackdrop: true,
    });

    return await modal.present();
  }
}
