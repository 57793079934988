import { Component } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { Platform } from '@ionic/angular';
import { AudioService } from './services/audio.service';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Device } from '@capacitor/device';
import { CmsService } from './services/cms.service';

import { NotificationService } from './services/notifications.service';
import { QuizzService } from './modules/quizz/services/quizz.service';
import { AnalyticsService } from './services/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  title = 'rs-app';
  authenticated : boolean = false;
  public isDesktop = false;

  constructor(
    public auth: AuthenticationService, 
    private platform: Platform,
    private audioService: AudioService,
    public cms : CmsService,
    private notifications: NotificationService,
    private quizz:QuizzService,
    private analytics : AnalyticsService
  ) {


    this.auth.currentAuthUser.subscribe( user=>{
      this.authenticated = user && user.exists() ? true : false;    
    })
    this.platform.ready().then(() => {
      // Change the status bar color
      Device.getInfo().then((info)=>{
        if ( info.platform==='ios' || info.platform==='android') {
          console.log
          StatusBar.setStyle({ style: Style.Dark });
        } else {
          if (! this.platform.is('mobileweb')) {
            this.isDesktop = true;
          }
          
        }
      })
      
    });

    this.platform.pause.subscribe(() => {
      this.audioService.pauseBackgroundAudio();
    });

    this.platform.resume.subscribe(() => {
        this.audioService.resumeBackgroundAudio();
    });

  }


}
