
export class SearchItem {
  public id: number | null = null;
  public lang: string = null;
  public content_id: number | null = null;
  public media_url: string | null = null;
  public title: string | null = null;
  public perms: string[] | null = null;
  public size: number | null = null;
  public url: string = null;
  public event_id: number | null = null;
  public date_from: string = null;
  public date_to: string = null;
  public location: string = null;
  
  constructor(data) {
    if (data) {
      this.import(data);
    }
  }

  import(data) {
    for (var key in data) {
      if (this.hasOwnProperty(key)) {
        switch (key) {
          
          default:
            this[key] = data[key];
        }
      }
    }
  }

  exists() {
    return !!this.id;
  }
}
