export class ContentBlock {
    id: number | null = null;
    content_id: number | null = null;
    type: string | null = null;
    order: number | null = null;
    title: string | null = null;
    data: any = null;
    data_contents: any[] = [];
   

    constructor(data) {
        if(data) {
            this.import(data);
        }
    }

    import(data) {
        for (var key in data) {
            if (this.hasOwnProperty(key)) {
                switch (key) {
                    default:
                        this[key] = data[key];
                }
            }
        }
    }

    exists() {
        return !!this.id;
    }
}