import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HasRolesDirective } from 'src/app/directives/hasroles.directive';
import { AvatarComponent } from '../quizz/components/avatar/avatar.component';
import { LoginComponent } from 'src/app/components/auth/login/login.component';
import { RegisterComponent } from 'src/app/components/auth/register/register.component';
import { AuthModalComponent } from '../auth/auth-modal/auth-modal.component';
import { MomentModule } from 'ngx-moment';
import { LoaderComponent } from 'src/app/components/cms/blocks/loader/loader.component';

import { register } from 'swiper/element/bundle';

register();
@NgModule({
  declarations: [
    HasRolesDirective,
    AvatarComponent,
    LoginComponent,
    RegisterComponent,
    AuthModalComponent,
    LoaderComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    MomentModule
  ],
  exports: [
    TranslateModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    HasRolesDirective,
    CommonModule,
    AvatarComponent,
    LoginComponent,
    RegisterComponent,
    AuthModalComponent,
    MomentModule,
    LoaderComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class SharedModule {}
