<ion-content class="cms-nav-background">
  <ion-fab
    class="cms-fab"
    *ngIf="menu_id"
    slot="fixed"
    vertical="top"
    horizontal="end"
  >
    <ion-fab-button (click)="cms.back()">
      <ion-icon name="chevron-back-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <ion-fab class="cms-fab" slot="fixed" vertical="top" horizontal="start">
    <ion-fab-button (click)="cms.showSideMenu()">
      <ion-icon name="menu-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <cms-loader
    
    size="small"
    
    *ngIf="!ready"
  ></cms-loader>
  <div *ngIf="ready" class="nav-wrapper">
    <div class="grid">
      <ng-container *ngFor="let item of menu_items; let idx=index">
        <div
          class="nav-item col-{{ item.size }} {{item.css}}"
          [class.marginTop]="item.margin_top"
          [class.marginBottom]="item.margin_bottom"
          [class.marginLeft]="item.margin_left"
          [class.marginRight]="item.margin_right"
          [class.first-navitem]="idx===0"
        >
          <cms-cached-image 
          mode="div"
            *ngIf="!item.is_separator"
            class="cell-wrapper"
            [src]="nav_path + '/' + item.media_url"
            [class.hand]="item.url || item.content_id"
            (click)="clickOnItem($event, item)"
          >
            <div *ngIf="item?.loading" class="loader">
              <cms-loader size="small"></cms-loader>
            </div>

            <div class="nav-title">
              <span>{{ item.title }}</span>
            </div>
          </cms-cached-image>

          <cms-cached-image 
          mode="div"
          *ngIf="item.is_separator"
          class="cell-separator"
          [src]="nav_path + '/' + item.media_url"
          ></cms-cached-image>
        
        </div>
      </ng-container>
      <cms-page-footer src="/assets/images/footer.jpg"></cms-page-footer>
    </div>

  </div>

  <octopus-menu></octopus-menu>

</ion-content>
