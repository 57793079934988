import { Component, Input, OnInit } from '@angular/core';
import { IonicSlides, ModalController } from '@ionic/angular';
import { CmsService } from 'src/app/services/cms.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cms-block-content-gallery',
  templateUrl: './content-gallery-block.component.html',
  styleUrls: ['./content-gallery-block.component.scss'],
})
export class ContentGalleryBlockComponent implements OnInit {
  public ready: boolean = false;
  public content_id: string = null;
  public img_path: string = environment.fileserver.images;
  public slideOpts = {
    initialSlide: 0,
    slidesPerView: 1.5,
    //centeredSlides:true,
    spaceBetween: 10,
    speed: 400,
  };

  public swiperModules = [IonicSlides];

  @Input() block = null;

  constructor(
    public cms: CmsService,
    protected modalController: ModalController
  ) {}

  ngOnInit() {}

  goTo(event, content) {
    this.cms.goTo(content.url)
  }
}
