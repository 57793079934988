import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Notification } from 'src/app/models/Notification';
import { CmsService } from 'src/app/services/cms.service';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { IonItemSliding } from '@ionic/angular';
import { NavItem } from 'src/app/models/NavItem';

@Component({
  selector: 'app-notifications-page',
  templateUrl: './notifications-page.component.html',
  styleUrls: ['./notifications-page.component.scss'],
})
export class NotificationsPageComponent implements OnInit {
  public ready: boolean = false;
  public notif_ready: boolean = false;
  public nav_path: string = environment.fileserver.images;
  public locale: string = 'en';
  public notifications = null;
  public header_item : NavItem = null;

  constructor(private translate: TranslateService, public cms: CmsService) {
    this.locale = this.translate.currentLang;
  }

  async ngOnInit() {
    this.cms.getMenu('notification-page').then((menu) => {
      console.log('NOTIF HEADER', menu);
      this.header_item = menu[0];
      this.ready = true;

      this.cms.getNotifications().then((notifs) => {
        this.notifications = notifs;
        this.notif_ready = true;
        
      });
    });
  }

  toggleDescription(notif: Notification) {
    notif.showDescription = !notif.showDescription;
    if (notif.unread) {
      notif.unread = false;
      this.cms.saveNotificationsToStorage(this.notifications);
    }
  }

  clickOnItem(event, item) {
    if (item.event_id) {
      this.cms.goTo(`/event/${item.event_id}`);
    } else if (item.content_id) {
      this.cms.goTo(`/content/${item.content_id}`);
    } else if (item.url) {
      this.cms.openLink(item.url);
    }
  }

  removeNotification(event, item, slidingItem: IonItemSliding) {
    this.notifications = _.remove(this.notifications, (o: any) => {
      return o.id != item.id;
    });
    slidingItem.close();
    this.cms.saveNotificationsToStorage(this.notifications);
  }

  goToMenu() {}
}
