<ng-container *ngIf="mode!='div'">
  <img class="cached-img" [src]="_src" *ngIf="_src != ''; else loading" />

  <ng-template #loading>
    <div class="img-placeholder">
      <ion-spinner name="bubbles"></ion-spinner>
    </div>
  </ng-template>
</ng-container>

<ng-container *ngIf="mode=='div'">
  <div  *ngIf="_src !=''; else loading"  class="content cached-background" [style.background-image]="'url('+_src+')'">
    <ng-content></ng-content>
  </div>
  <ng-template #loading>
    <div class="img-placeholder-div">
      <ion-spinner name="bubbles"></ion-spinner>
    </div>
  </ng-template>
</ng-container>
