import { AuthUser } from '../../../models/AuthUser';
import { User } from '../../../models/User';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../../services/authentication.service';
import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CmsService } from 'src/app/services/cms.service';
import { environment } from 'src/environments/environment';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { AudioService } from 'src/app/services/audio.service';
import { Device } from '@capacitor/device';

@Component({
  selector: 'app-layout-desktop',
  templateUrl: './app-layout-desktop.component.html',
  styleUrls: ['./app-layout-desktop.component.scss'],
})
export class AppLayoutDesktopComponent implements OnInit {
  user: User = null;
  authUser: AuthUser = null;
  ready = false;
  public isProd: boolean = false;
  @ViewChild('myAudio') myAudio: ElementRef;
  public isDesktop: boolean = false;

  public menu_side = null;
  public menu_account = null;
  public menu_socials = null;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result['matches']));

  constructor(
    private breakpointObserver: BreakpointObserver,
    public translate: TranslateService,
    public auth: AuthenticationService,
    public cms: CmsService,
    private dataService: DataService,
    protected router: Router,
    private zone: NgZone,
    private audioService: AudioService
  ) {
    console.log('### ####### ############ ########## #########################################################################');
    this.isProd = environment.production;
    this.auth.currentAuthUser.subscribe((user) => {
      console.log('USER', user);
      this.authUser = user;
      this.ready = true;
      if (this.authUser.exists()) {
        this.cms.getMenu('user-logged').then((menu) => {
          console.log('Menu logged=', menu);
          this.menu_account = menu;
        });
      } else {
        this.cms.getMenu('user-not-logged').then((menu) => {
          console.log('Menu !logged=', menu);
          this.menu_account = menu;
        });
      }
    });

    Device.getInfo().then((info) => {
      this.isDesktop = info.platform == 'web';
    });

    this.cms.localeChanged$.subscribe((locale)=>{
      console.log('AppLayoutComponent locale changed', locale)
      if (locale) {
       this.loadMenus();
      }
    })
  }

  ngOnInit() {
    setTimeout(() => {
      const audioEl = this.myAudio.nativeElement;
      // autostart audio
      this.audioService.setBackgroundAudioElement(audioEl, false);
    }, 500);
  }

  loadMenus() {
    this.cms.getMenu('side-menu').then((menu) => {
      this.menu_side = menu;
    });
    this.cms.getMenu('socials').then((menu) => {
      this.menu_socials = menu;
    });

   if (this.authUser.exists()) {
      this.cms.getMenu('user-logged').then((menu) => {
        console.log('Menu logged=', menu);
        this.menu_account = menu;
      });
    } else {
      this.cms.getMenu('user-not-logged').then((menu) => {
        console.log('Menu !logged=', menu);
        this.menu_account = menu;
      });
    }
  }

  async onMenuClick(item) {
    if (item.url) {
      if (item.is_external) {
        // external link
        this.cms.openLink(item.url);
        return;
      }
      this.cms.goTo(item.url);
    } else if (item.content_id) {
      // regular url
      let url = `/content/${item.content_id}`;
      this.cms.goTo(url);
    }
  }

  goHome(event) {
    console.log('goHome');
    this.cms.goHome();
  }

  
}
