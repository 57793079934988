<!-- <ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="start">
      <ion-button (click)="closeModal()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header> -->

<ion-content [forceOverscroll]="false" color="dark" fullscreen="true">

    <ng-container *ngIf="!isDesktop">
      <ion-fab class="cms-fab" slot="fixed" vertical="top" horizontal="start">
        <ion-fab-button (click)="closeModal()">
          <ion-icon name="close"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ng-container>
    <ng-container *ngIf="isDesktop">
      <ion-fab class="cms-fab" slot="fixed" vertical="top" horizontal="end">
        <ion-fab-button (click)="closeModal()">
          <ion-icon name="close"></ion-icon>
        </ion-fab-button>
      </ion-fab>
      <ion-fab *ngIf="_slides.length>1" class="cms-fab" slot="fixed" vertical="center" horizontal="start">
        <ion-fab-button (click)="previousSlide()">
          <ion-icon name="chevron-back-outline"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    
      <ion-fab  *ngIf="_slides.length>1" class="cms-fab" slot="fixed" vertical="center" horizontal="end">
        <ion-fab-button (click)="nextSlide()">
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ng-container>

  

  

  <!-- <div class="orientation"> -->
    <swiper-container
    
    
      #slider
      (afterInit)="swiperReady()"
      paginationType="bullets"
      loop="true"
      (ionSlideDidChange)="onSlideChanged($event)"
      [direction]="direction"
      zoom="true"
      slides-per-view="auto"
      
      
    >
      <swiper-slide  *ngFor="let img of _slides; let idx = index">
        <div
        class="rotation-container {{img?.type}}"
        [class.landscape]="true"
      >
        <div  *ngIf="img.type!=='video'" class="swiper-zoom-container">

        <!-- <cms-cached-image
          mode="img"
          class="slide-img"
          [src]="img_path + '/' + img"
        ></cms-cached-image> -->

          <img [src]="img.url"  /> 
        <!-- <img *ngIf="img.type!=='video'" [src]="img_path + '/' + img" (load)="onImageLoad($event, img)" />  -->
        </div>
        <div class="video-container"  *ngIf="img.type==='video'">
            <div [id]="'viewer-vimeo-player-'+idx" class="video vimeo-player"></div>
            <div class="overlay" (click)="toggleVimeo()">
              <ion-icon
                *ngIf="!videoPlaying"
                name="play-circle"
              ></ion-icon>
              <ion-icon *ngIf="!isDesktop" class="expand-bt" name="expand" (click)="fullscreenVideo()"></ion-icon>
            </div>

          </div>

        
      </div>
      </swiper-slide>
    </swiper-container>
  <!-- </div> -->
</ion-content>
