<ion-content class="cms-nav-background" id="main-content">
  <ion-fab class="cms-fab" slot="fixed" vertical="top" horizontal="end">
    <ion-fab-button (click)="cms.back()">
      <ion-icon name="chevron-back-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <ion-fab class="cms-fab" slot="fixed" vertical="top" horizontal="start">
    <ion-fab-button (click)="cms.showSideMenu()">
      <ion-icon name="menu-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <cms-loader size="small" *ngIf="!ready"></cms-loader>
  <div *ngIf="ready" class="nav-wrapper">
    <div class="grid">
      <ng-container *ngFor="let item of events; let idx = index">
        <div
          class="nav-item col-{{ item.size }}"
          [class.marginTop]="item.margin_top"
          [class.marginBottom]="item.margin_bottom"
          [class.marginLeft]="item.margin_left"
          [class.marginRight]="item.margin_right"
          [class.first-navitem]="idx === 0"
        >
          <cms-cached-image
            mode="div"
            class="cell-wrapper"
            [src]="nav_path + '/' + item.media_url"
            [class.hand]="item.url || item.content_id"
            (click)="clickOnItem($event, item)"
          >
            <div *ngIf="item?.loading" class="loader">
              <ion-spinner color="primary" class="big-spinner"></ion-spinner>
            </div>

            <div class="nav-title">
              <div class="date" *ngIf="item.date_format === 'datetime'">
                <!-- with date and time -->
                <span class="month"
                  >{{
                    item.date_from | amLocale : locale | amDateFormat : "DD"
                  }}
                  {{
                    item.date_from
                      | amLocale : locale
                      | amDateFormat : "MMM"
                      | stringLimit : 3
                  }}</span
                >
                <span class="day">{{
                  item.date_from | amLocale : locale | amDateFormat : "HH:mm"
                }}</span>
              </div>

              <div class="date" *ngIf="item.date_format === 'date'">
                <!-- with date only -->
                <ng-container *ngIf="!item.date_to">
                  <!-- only one day -->
                  <span class="month">{{
                    item.date_from | amLocale : locale | amDateFormat : "DD"
                    
                  }}</span>
                  <span class="day">{{
                    item.date_from
                      | amLocale : locale
                      | amDateFormat : "MMM"
                      | stringLimit : 3
                  }}</span>
                </ng-container>
                <ng-container *ngIf="item.date_to">
                  <!-- several days -->
                  <span class="month"
                    >{{
                      item.date_from | amLocale : locale | amDateFormat : "DD"
                    }}
                    {{
                      item.date_from
                        | amLocale : locale
                        | amDateFormat : "MMM"
                        | stringLimit : 3
                    }}
                  </span>
                  <span class="day"
                    >{{
                      item.date_to | amLocale : locale | amDateFormat : "DD"
                    }}
                    {{
                      item.date_to
                        | amLocale : locale
                        | amDateFormat : "MMM"
                        | stringLimit : 3
                    }}</span
                  >
                </ng-container>
              </div>
              <div class="title-event">
                <span>{{ item.title }}</span>
              </div>
            </div>
          </cms-cached-image>
        </div></ng-container
      >
      <cms-page-footer src="/assets/images/footer.jpg"></cms-page-footer>
    </div>
  </div>
  <octopus-menu></octopus-menu>
</ion-content>
