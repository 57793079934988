import { Injectable } from '@angular/core';
import { AppLauncher } from '@capacitor/app-launcher';
import { Browser } from '@capacitor/browser';
import { Device } from '@capacitor/device';
import { Network } from '@capacitor/network';
import { MenuController, NavController } from '@ionic/angular';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Content } from '../models/Content';
import { Event } from '../models/Event';
import { NavItem } from '../models/NavItem';
import { SearchItem } from '../models/SearchItem';
import { AuthenticationService } from './authentication.service';
import { DataService } from './data.service';
import { environment } from 'src/environments/environment';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Notification } from '../models/Notification';
import { Storage } from '@ionic/storage-angular';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AnalyticsService } from './analytics.service';

@Injectable({
  providedIn: 'root',
})
export class CmsService {
  public pageTitle = '';
  public menus: any = {};
  public isDesktop: boolean = false;
  public locale = null;
  public isNetworkActive: boolean = true;
  private _storage: Storage | null = null;
  public unread_notifications: boolean = false;

  public schemes = [
    { prefix: 'https://www.facebook.com/', scheme: 'fb://page/' },
    { prefix: 'https://www.instagram.com/', scheme: 'instagram://' },
    { prefix: 'https://www.youtube.com/', scheme: 'youtube://' },
  ];

  private mainMenuReadySubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public mainMenuReady$: Observable<any> =
    this.mainMenuReadySubject.asObservable();

  private localeChangedSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public localeChanged$: Observable<any> =
    this.localeChangedSubject.asObservable();

  protected main_menu = null;

  constructor(
    public auth: AuthenticationService,
    public translate: TranslateService,
    private dataService: DataService,
    protected navController: NavController,
    protected menuController: MenuController,
    private storage: Storage,
    private analytics: AnalyticsService

  ) {
    this.locale = this.locale = this.translate.currentLang;
    this.initStorage();

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const previous_locale = this.locale;
      console.log(event.lang, event);
      this.locale = this.locale = this.translate.currentLang;
      this.localeChangedSubject.next(this.locale);

      // // reload the menus
      // if (previous_locale && this.menus[previous_locale]) {
      //   Object.keys(this.menus[previous_locale]).forEach( (menu_id)=>{
      //     console.log('reload menu : ', menu_id)
      //     this.getMenu(menu_id);
      //   })
      // }
    });

    this.createCacheFolder();
    setTimeout(() => {
      this.calculateCacheSize().then((size) => {
        console.log('CACHE SIZE=', size);
        this.removeOldCachedFiles(30 * 24 * 3600);
      });
    }, 2000);

    this.loadCustomCss();

    this.getMenu().then((nav) => {
      this.mainMenuReadySubject.next(nav);
    });

    Device.getInfo().then((info) => {
      this.isDesktop = info.platform == 'web';
    });

    this.observeNetworkStatus();

    this.getNotifications();
  }

  async initStorage() {
    const storage = await this.storage.create();
    this._storage = storage;
  }

  observeNetworkStatus() {
    Network.addListener('networkStatusChange', async (status) => {
      if (status.connected) {
        this.isNetworkActive = true;
        console.log('NETWORK IS BACK');
      } else {
        console.log('NETWORK DISCONNECTED');
        this.isNetworkActive = false;
      }
    });
  }

  getMenu(menu_id = 'main-menu') {
    if (this.menus[this.locale] && this.menus[this.locale][menu_id]) {
      return Promise.resolve(this.menus[this.locale][menu_id]);
    }
    return this.loadMenu(menu_id).then((nav) => {
      if (!this.menus[this.locale]) {
        this.menus[this.locale] = [];
      }
      this.menus[this.locale][menu_id] = nav;
      return Promise.resolve(nav);
    });
  }

  logout() {
    this.auth.logout();
  }

  setTitle(title, subtitle = '') {
    if (title) {
      this.pageTitle = this.translate.instant(title);
      if (subtitle) {
        this.pageTitle += ' : ' + subtitle;
      }
    } else {
      this.pageTitle = '';
    }
  }

  getPageTitle() {
    return this.pageTitle;
  }

  loadMenu(menu_id = null) {
    let endpoint = 'cms/nav';
    if (menu_id) {
      endpoint += '/' + menu_id;
    }
    const params = {
      locale: this.locale,
    };
    return new Promise((resolve, reject) => {
      this.dataService.getAsPromise(endpoint, {params:params}, true).then((res: any) => {
        if (res && res.data) {
          const items = [];
          res.data.forEach((item) => {
            items.push(new NavItem(item));
          });

          resolve(items);
        } else {
          resolve([]);
        }
      });
    });
  }

  back() {
    this.navController.back();
  }

  showSideMenu() {
    this.menuController.open('start');
  }

  goHome() {
    this.navController.navigateRoot('/');
    this.menuController.close('start');
  }

  goTo(url) {
    this.navController.navigateForward(url);
    this.menuController.close('start');
  }

  async openLink(url) {
    if (url) {
      const scheme = this.getScheme(url);
      if (scheme && !this.isDesktop) {
        const { value } = await AppLauncher.canOpenUrl({ url: scheme.scheme });
        if (value) {
          url = url.replace(scheme.prefix, scheme.scheme);
          await AppLauncher.openUrl({ url: url });
          this.analytics.logEvent('app', {app:url});

          return;
        }
      }
      // external link
      await Browser.open({ url: url });
      this.analytics.logEvent('website', {website:url});
      return;
    }
  }

  getScheme(url) {
    for (let i = 0; i < this.schemes.length; i++) {
      if (url.indexOf(this.schemes[i].prefix) === 0) {
        return this.schemes[i];
      }
    }
    return null;
  }

  isNumeric(value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }

  getContent(content_id) {
    console.log('LOAD CONTENT');
    let endpoint = 'cms/content';
    if (content_id) {
      if (this.isNumeric(content_id)) {
        endpoint += '/' + content_id;
      } else {
        endpoint += '/' + encodeURIComponent(content_id);
      }
    }

    return new Promise((resolve, reject) => {
      this.dataService
        .getAsPromise(endpoint, {}, true)
        .then((res: any) => {
          if (res && res.data) {
            if (res.data.type === 'event') {
              resolve(new Event(res.data));
            } else {
              resolve(new Content(res.data));
            }
          } else {
            this.navController.navigateRoot('/');
            resolve(null);
          }
        })
        .catch((err) => {
          console.log('Error loading content:', content_id);
          this.navController.navigateRoot('/');
          resolve(null);
        });
    });
  }

  search(str) {
    console.log('SEARCH CONTENT');
    let endpoint = 'cms/search';

    const params = {
      search: str,
    };

    return new Promise((resolve, reject) => {
      this.dataService
        .postAsPromise(endpoint, params)
        .then((res: any) => {
          if (res && res.data) {
            let items = [];
            res.data.forEach((element) => {
              items.push(new SearchItem(element));
            });
            resolve(items);
          } else {
            resolve([]);
          }
        })
        .catch((err) => {
          console.log('Error searching content:');

          resolve([]);
        });
    });
  }

  getEvent(event_id) {
    console.log('LOAD EVENT');
    let endpoint = `cms/event/${event_id}`;
    return new Promise((resolve, reject) => {
      this.dataService
        .getAsPromise(endpoint, {})
        .then((res: any) => {
          if (res && res.data) {
            resolve(new Event(res.data));
          } else {
            this.navController.navigateRoot('/events');
            resolve(null);
          }
        })
        .catch((err) => {
          console.log('Error loading event:', event_id);
          this.navController.navigateRoot('/events');
          resolve(null);
        });
    });
  }

  getEvents() {
    console.log('LOAD EVENTS');
    let endpoint = 'cms/events';

    return new Promise((resolve, reject) => {
      this.dataService
        .getAsPromise(endpoint, {})
        .then((res: any) => {
          if (res && res.data) {
            const items = [];
            res.data.forEach((item) => {
              items.push(new Event(item));
            });

            resolve(items);
          } else {
            this.navController.navigateRoot('/');
            resolve(null);
          }
        })
        .catch((err) => {
          console.log('Error loading events:');
          this.navController.navigateRoot('/');
          resolve(null);
        });
    });
  }

  getNotifications(params = {}) {
    let endpoint = 'cms/notifications';

    return new Promise(async (resolve, reject) => {
      let notifs = await this.getNotificationsFromStorage();

      let d = await this.getLastGetNotifications();
      if (!d) {
        d = moment().utc().toISOString();
      }
      this.setLastGetNotifications(moment().utc().toISOString());

      const params = { date_from: d };

      this.dataService
        .postAsPromise(endpoint, params)
        .then((res: any) => {
          if (res && res.data) {
            res.data.forEach((item) => {
              const idx = _.findIndex(notifs, { id: item.id })
              if (idx==-1) {
                //not found
                notifs.unshift(new Notification(item));
                this.unread_notifications = true;
              } else {
                // found
                notifs[idx] = new Notification(item);
                this.unread_notifications = true;
              }
            });
            
          }
          notifs = _.orderBy(notifs, ['created_at'], ['desc']);
          this.saveNotificationsToStorage(notifs);
          resolve(notifs);
        })
        .catch((err) => {
          console.log('Error loading notifications:');
          resolve([]);
        });
    });
  }

  changeLang(lang) {
    this.translate.use(lang);
    localStorage.setItem('locale', lang);
  }

  loadCustomCss() {
    const styleName = environment.api.root + '/cms/styles';

    const head = document.getElementsByTagName('head')[0];

    let themeLink = document.getElementById('client-theme') as HTMLLinkElement;
    if (themeLink) {
      // If we already have a theme link, update its href
      themeLink.href = styleName;
    } else {
      // Else, create the link element
      const style = document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.type = 'text/css';
      style.href = `${styleName}`;

      // Append the link element to the head
      head.appendChild(style);
    }
  }

  async createCacheFolder() {
    if (environment['cache_folder']) {
      try {
        await Filesystem.mkdir({
          directory: Directory.Cache,
          path: environment['cache_folder'],
        });
      } catch (e) {
        console.log('Cache already created');
      }
    }
  }

  calculateCacheSize() {
    return new Promise(async (resolve, reject) => {
      if (environment['cache_folder']) {
        const result = await Filesystem.readdir({
          path: environment['cache_folder'],
          directory: Directory.Cache,
        });
        let totalSize = 0;

        for (let f of result.files) {
          //console.log(f);
          totalSize += f.size;
        }

        resolve(totalSize);
      }
      resolve(null);
    });
  }

  async removeOldCachedFiles(max_lifetime_seconds) {
    if (!environment['cache_folder']) {
      return;
    }

    const result = await Filesystem.readdir({
      path: environment['cache_folder'],
      directory: Directory.Cache,
    });

    const delete_before = new Date().getTime() - max_lifetime_seconds * 1000;

    for (let f of result.files) {
      if (f.mtime < delete_before) {
        await Filesystem.deleteFile({
          path: environment['cache_folder'] + '/' + f.name,
          directory: Directory.Cache,
        });
      }
    }
  }

  async getLastGetNotifications() {
    let d = await this._storage.get('last_notifications');
    return d;
  }

  async setLastGetNotifications(d: string) {
    await this._storage.set('last_notifications', d);
  }

  async saveNotificationsToStorage(notifications: any[]) {
    await this._storage.set('notifications', notifications);

    this.unread_notifications = false;
    notifications.forEach((item) => {
      if (item.unread) {
        this.unread_notifications = true;
      }
    });

  }

  async getNotificationsFromStorage() {
    if (!this._storage) {
      await this.initStorage();
    }
    let data = await this._storage.get('notifications');
    let notifs = [];
    if (data) {
      //const arr = JSON.parse(data);
      data.forEach((item) => {
        let n = new Notification(item);
        n.showDescription = false;
        if (n.unread) {
          this.unread_notifications = true;
        }
        notifs.push(n);
      });
    }
    notifs = _.orderBy(notifs, ['created_at'], ['desc']);
    return notifs;
  }

}
