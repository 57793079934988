import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { CmsService } from 'src/app/services/cms.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nav-page',
  templateUrl: './nav-page.component.html',
  styleUrls: ['./nav-page.component.scss'],
})
export class NavPageComponent implements OnInit {
  public ready: boolean = false;
  public nav_path: string = environment.fileserver.images;
  public menu_id: string = null;

  public menu_items = null;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    public cms: CmsService,
    private analytics: AnalyticsService
  ) {
    this.route.paramMap.subscribe((params) => {
      console.log('PARAMS', params);
      this.menu_id = params.get('menu_id') || 'main-menu';
      this.cms.getMenu(this.menu_id).then((menu) => {
        this.menu_items = this.prepareMenu(menu);
        this.ready = true;
        this.analytics.setScreenName('navigation', this.menu_id);
      });
    });

    this.cms.localeChanged$.subscribe((locale) => {
      console.log('AppLayoutComponent locale changed', locale);
      if (locale) {
        this.cms.getMenu(this.menu_id || 'main-menu').then((menu) => {
          this.menu_items = this.prepareMenu(menu);
          this.analytics.setScreenName('navigation', this.menu_id);
        });
      }
    });

    console.log('ENV', environment);
  }

  ngOnInit() {}

  ngOnDestroy() {}

  ionViewDidEnter() {}

  prepareMenu(menu) {
    const menu_items = [];
    let psize = 0;
    for (let m of menu) {
      m.margin_top = true;

      switch (m.size) {
        case 12:
          if (psize === 0) {
            m.margin_top = false;
          }
          break;

        case 6:
          if (psize == 6) {
            m.margin_left = true;
          } else {
            m.margin_right = true;
          }
          break;
      }

      menu_items.push(m);
      psize = m.size;
    }
    return menu_items;
  }

  clickOnItem(event, item) {
    if (item.url) {
      if (item.url.indexOf('/nav/') === 0) {
        // preload the menu
        item.loading = true;
        this.cms.loadMenu(item.url.replace('/nav/', '')).then(() => {
          item.loading = false;
          this.router.navigate([item.url]);
        });
      } else {
        // regular url
        this.cms.goTo(item.url);
      }
    } else if (item.content_id) {
      // regular url
      let url = `/content/${item.content_id}`;
      this.cms.goTo(url);
    }
  }

  goToMenu() {}
}
