import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { AlertController } from '@ionic/angular';
import { AuthUser } from 'src/app/models/AuthUser';
import { User } from 'src/app/models/User';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';
import { Avatar } from '../models/Avatar';
import { Player } from '../models/Player';
import { Level, UserGame } from '../models/UserGame';

@Injectable({
  providedIn: 'root',
})
export class QuizzService {
  public authUser: AuthUser = null;
  public user: User = null;
  public player: Player = null;

  public _tempPlayer = null;
  public userGame: UserGame = new UserGame();
  public currentLevel: Level = { level: 0, right: 0, wrong: 0 };
  public defaultUserGame: UserGame = new UserGame();

  public questions: any[] = [];
  public wheelquestion: any = null;

  public current_question_idx: number = 0;
  public current_question: any = null;
  public current_question_answer: any = null;
  public current_block: string = null;
  public answer_status = null;

  public last_level: number = 5;

  public levelCount: number = 0;
  public defaultAvatar: Avatar = null;
  public defaultDifficulty: string = null;

  constructor(
    public auth: AuthenticationService,
    public translate: TranslateService,
    private dataService: DataService,
    private alertController: AlertController
  ) {
    console.log('===============================QuizzService=======');

    this.player = Player.factory(this.auth, this.dataService);
    this.player.load();

    this.auth.currentAuthUser.subscribe((authUser) => {
      this.authUser = authUser;
      if (!this.authUser.exists()) {
      }
    });

    this.auth.currentUser.subscribe((user) => {
      if (user) {
        this.user = user;

        console.log('quiServ current user changed', user);
        // init level count
        if (user.level_count) {
          this.setLevelCount(user.level_count);
        }

        if (user.last_game) {
          // check if last_game is the current anonymous game
          if (this.player.isAnonymousGame()) {
            // compare the 2 games
            if (
              user.last_game.game_id == this.player.userGame.game_id &&
              user.last_game.category_id == this.player.userGame.category_id &&
              user.last_game.difficulty == this.player.userGame.difficulty
            ) {
              let buttons = [
                {
                  text: this.translate.instant('BUTTONS.BT_NO'),
                  handler: () => {
                    this.player.userGame.id = user.last_game.id;
                    this.player.saveLocal();
                  },
                },
                {
                  text: this.translate.instant('BUTTONS.BT_YES'),
                  handler: () => {
                    this.player.load(user.last_game);
                    this.player.saveLocal();
                  },
                },
              ];

              let alertHeader = this.translate.instant('QUIZZ.LOAD_SAME_GAME');
              let alertMessage = this.translate.instant(
                'QUIZZ.LOAD_SAME_MESSAGE'
              );
              this.presentAlert(alertMessage, buttons, alertHeader);
            }
          } else {
            // we overwrite the current game

            this.player.load(new UserGame(user.last_game));
            this.player.saveLocal();
          }
        }
      }
    });

    // current game
    this.player.gameObservable().subscribe((game) => {
      console.log('game========', game);
      if (game) {
        this.reset();
      }
    });
  }

  setCurrentGame(game: UserGame) {
    //this.currentGameSubject.next(game);
  }

  getQuestions() {
    if (!this.player.canStartGame()) {
      return Promise.resolve(false);
    }
    let params = {
      level: this.player.userGame.level + 1,
      difficulty: this.player.userGame.difficulty,
      game_id: this.player.userGame.game_id,
      category_id: this.player.userGame.category_id,
    };
    if (environment.rs.debug_questions) {
      params['debug'] = 1;
    }
    return this.dataService
      .getAsPromise('rs/quizz/questions', { params: params })
      .then((res) => {
        if (res && res.data) {
          this.questions = res.data;
          // this.nextQuestion(0);

          return Promise.resolve(res.data);
        }
        return Promise.resolve(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getWheelQuestion(params) {
    return this.dataService
      .postAsPromise('rs/quizz/wheelquestion', params)
      .then((res) => {
        if (res && res.data) {
          this.wheelquestion = res.data;
          return Promise.resolve(res.data);
        }
        return Promise.resolve(false);
      });
  }

  isEndOfGame(level = null) {
    if (!level) {
      level = this.player.getLevel();
    }
    if (level >= this.last_level) {
      return true;
    }
    return false;
  }

  gameOver() {
    const today = moment().format('Y-MM-DD');
    // continue the game, next day, with 3 lives
    if (!this.player.getLives()) {
      if (this.player.userGame.updated_at < today) {
        this.player.setLives(3);
        if (!this.user) {
          // remove the game over from storage
          this.player.removeGameOver();
        }
        console.log('GAME IS NOT OVER');
        return false;
      } else {
        // game over
        // this.title = this.translate.instant("QUIZZ_PAGE.GAME_OVER_TITLE");
        this.current_question = null;
        if (!this.user) {
          // store the game over in storage
          this.player.rememberGameOver();
        }
        console.log('GAME IS OVER');
        return true;
      }
    } else {
      return false;
    }
  }

  isLastQuestion() {
    return this.questions.length <= this.current_question_idx + 1;
  }

  setAnswerStatus(status) {
    this.answer_status = status;
  }

  getTempPlayer() {
    // let tempPlayer = this.auth.getTempPlayer();
    // if (tempPlayer) {
    //   this.tempPlayer = tempPlayer;
    //   console.log('tempPlayer=', tempPlayer)
    //   this.setCurrentGame(this.tempPlayer.userGame);
    // }
  }

  setBlock(block = null) {
    this.current_block = block;
  }

  getBlock() {
    return this.current_block;
  }

  setCurrentQuestion(question) {
    this.current_question = question;
  }

  getCurrentQuestion() {
    return this.current_question;
  }

  setCurrentQuestionIndex(index) {
    this.current_question_idx = index;
  }

  getCurrentQuestionIndex() {
    return this.current_question_idx;
  }

  setCurrentQuestionAnswer(answer) {
    this.current_question_answer = answer;
  }

  getCurrentQuestionAnswer() {
    return this.current_question_answer;
  }

  getCurrentQuestions() {
    return this.questions;
  }

  reset() {
    this.questions = [];
    this.current_question_idx = 0;
    this.current_question_answer = null;
    this.resetLevel(this.player.getLevel());
  }

  resetLevel(level = 0) {
    this.currentLevel = { level: level, right: 0, wrong: 0 };
  }

  rightAnswer() {
    this.currentLevel.right++;
  }

  wrongAnswer() {
    this.currentLevel.wrong++;
  }

  getCurrentLevel() {
    return this.currentLevel.level;
  }

  memorizeCurrentLevel() {
    if (!this.player.userGame.levels) {
      this.player.userGame.levels = [];
      for (let i = 0; i < 5; i++) {
        this.player.userGame.levels.push({ level: i, right: 0, wrong: 0 });
      }
    }
    let l = this.currentLevel.level ? this.currentLevel.level : 0;
    if (this.currentLevel.level == null) {
      this.currentLevel.level = 0;
    }
    this.player.userGame.levels[this.currentLevel.level] = this.currentLevel;
  }

  nextLevel() {

    this.player.nextLevel();
    if (this.player.getLevel() < 5) {
      this.resetLevel(this.player.userGame.level);
    }
  }

  // check if we have zero errors on all levels
  deserveBigBadge() {
    console.log('deserveBigBadge', this.userGame.levels);
    for (let level of this.userGame.levels) {
      console.log('level', level);
      if (level.right != 0 && level.wrong != 0) {
        return false;
      }
    }
    return true;
  }

  getLevelCount() {
    if (this.user) {
      return this.levelCount;
    } else {
      if (this.userGame) {
        return this.userGame.level;
      }
    }
    return 0;
  }

  setLevelCount(level: number) {
    this.levelCount = level;
  }

  getDefaultAvatar() {
    return this.defaultAvatar;
  }

  setDefaultAvatar(avatar: Avatar) {
    this.defaultAvatar = avatar;
    this.auth.setTempPlayerAttributes({
      defaultAvatar: avatar,
    });
  }

  getDefaultDifficulty() {
    return this.defaultDifficulty;
  }

  setDefaultDifficulty(difficulty: string) {
    this.defaultDifficulty = difficulty;
    this.auth.setTempPlayerAttributes({
      defaultDifficulty: difficulty,
    });
  }

  resetAll() {
    console.log('resetAll');
    this.reset();
    //this.tempPlayer = null;
    this.userGame = null;
    this.currentLevel = { level: 0, right: 0, wrong: 0 };
    this.defaultUserGame = new UserGame();
    this.wheelquestion = null;
    this.current_question = null;
    this.current_block = null;
    this.answer_status = null;
    this.levelCount = 0;
    this.defaultAvatar = null;
    this.defaultDifficulty = null;
    this.setCurrentGame(null);
  }

  async presentAlert(msg, buttons = [], header = null) {
    let default_buttons = [
      {
        text: this.translate.instant('BUTTONS.BT_NO'),
      },
    ];
    let btns = default_buttons;
    if (buttons) {
      btns = buttons.concat(default_buttons);
    }
    btns = _.uniqBy(btns, _.property('text'));

    const alert = await this.alertController.create({
      header: header,
      message: msg,
      buttons: btns,
    });

    await alert.present();
  }
}
