import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { GalleryBlockComponent } from 'src/app/components/cms/blocks/gallery/gallery-block.component';
import { ImageBlockComponent } from 'src/app/components/cms/blocks/image/image-block.component';
import { TextBlockComponent } from 'src/app/components/cms/blocks/text/text-block.component';
import { VideoBlockComponent } from 'src/app/components/cms/blocks/video/video-block.component';
import { ContentPageComponent } from 'src/app/pages/content/content-page.component';
import { NavPageComponent } from 'src/app/pages/nav/nav-page.component';
import { SharedModule } from './shared.module';
import { IonicModule } from '@ionic/angular';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { ContentGalleryBlockComponent } from 'src/app/components/cms/blocks/content-gallery/content-gallery-block.component';
import { EventsPageComponent } from 'src/app/pages/events/events-page.component';
import { Calendar } from '@ionic-native/calendar/ngx';
import { SearchPageComponent } from 'src/app/pages/search/search-page.component';

import { register } from 'swiper/element/bundle';
import { OctopusMenuComponent } from 'src/app/components/octopus-menu/octopus-menu.component';
import { CachedImageComponent } from 'src/app/components/cms/cached-image/cached-image.component';
import { NotificationsPageComponent } from 'src/app/pages/notifications/notifications-page.component';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { IonicStorageModule } from '@ionic/storage-angular';
import { PageFooterComponent } from 'src/app/components/cms/page-footer/page-footer.component';
import { StringLimitPipe } from 'src/app/pipes/stringLimit.pipe';
import { GalleryViewerComponent } from 'src/app/components/cms/gallery-viewer/gallery-viewer.component';

register();

@NgModule({
  declarations: [
    NavPageComponent,
    ContentPageComponent,
    EventsPageComponent,
    TextBlockComponent,
    ImageBlockComponent,
    GalleryBlockComponent,
    VideoBlockComponent,
    ContentGalleryBlockComponent,
    SearchPageComponent,
    OctopusMenuComponent,
    CachedImageComponent,
    NotificationsPageComponent,
    PageFooterComponent,
    StringLimitPipe,
    GalleryViewerComponent
    

  ],
  imports: [
    IonicModule.forRoot(),
    SharedModule,
    YouTubePlayerModule,
    Nl2BrPipeModule,
    IonicStorageModule.forRoot()
  ],
  exports: [
    NavPageComponent,
    ContentPageComponent,
    EventsPageComponent,
    TextBlockComponent,
    ImageBlockComponent,
    GalleryBlockComponent,
    VideoBlockComponent,
    ContentGalleryBlockComponent,
    SearchPageComponent,
    CachedImageComponent,
    NotificationsPageComponent,
    Nl2BrPipeModule,
    PageFooterComponent,
    StringLimitPipe,
    GalleryViewerComponent
  ],
  providers:[
    Calendar
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class CmsdModule {}
