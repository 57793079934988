import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as _ from 'lodash';
import * as moment from 'moment';
//import 'moment/locale/fr';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthUser } from './../models/AuthUser';
import { User } from './../models/User';
import { DataService } from './data.service';
import { TempPlayer } from '../models/TempPlayer';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import {
  SignInWithApple,
  SignInWithAppleOptions,
  SignInWithAppleResponse,
} from '@capacitor-community/apple-sign-in';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public authenticated = false;
  protected api_root = environment.api.root;
  public isLoading: boolean = false;
  private currentAuthUserSubject: BehaviorSubject<AuthUser>;
  public currentAuthUser: Observable<AuthUser>;
  private tokenRefreshedSubject: BehaviorSubject<String>;
  public tokenRefreshed: Observable<String>;
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private http: HttpClient;
  private _tokenInterval = null;
  private _tokenIntervalDuration = 60; // duration in seconds

  constructor(private handler: HttpBackend, private data: DataService) {
    this.http = new HttpClient(handler);
    this.isLoading = true;
    console.log('AuthService');

    this.loadCurrentAuthUser().then((res) => {
      this.currentAuthUser.subscribe((user) => {
        if (user && user.exists()) {
          // autorefresh token
          this.autorefreshToken();

          if (!this.currentUserSubject.value) {
            setTimeout(() => {
              this.loadCurrentUser(true)
                .then(() => {
                  this.isLoading = false;
                })
                .catch((e) => {
                  console.log('Error:', e);
                  this.isLoading = false;
                });
            }, 1500);
          } else {
            this.isLoading = false;
          }
        } else {
          if (this.currentUserSubject.value) {
            this.currentUserSubject.next(null);
          }
          this.isLoading = false;
        }
      });
    });
  }

  private loadCurrentAuthUser() {
    return new Promise((resolve, reject) => {
      let user = new AuthUser(JSON.parse(localStorage.getItem('currentUser')));

      this.currentAuthUserSubject = new BehaviorSubject<AuthUser>(user);
      this.currentAuthUser = this.currentAuthUserSubject.asObservable();

      this.currentUserSubject = new BehaviorSubject<User>(null);
      this.currentUser = this.currentUserSubject.asObservable();

      this.tokenRefreshedSubject = new BehaviorSubject<String>(null);
      this.tokenRefreshed = this.tokenRefreshedSubject.asObservable();

      const now = new Date();
      // revalidating token
      if (user.access_token && user.expires && user.expires <= now.getTime()) {
        this.refreshToken()
          .then((user) => {
            resolve(user);
          })
          .catch((err) => {
            //this.logout();
            resolve(null);
          });
      } else {
        this.tokenRefreshedSubject.next(user.access_token);
        resolve(user);
      }
    });
  }

  public loginWithEmail(credentials) {
    const url = this.api_root + '/auth/login';

    return new Promise((resolve, reject) => {
      this.http
        .post(url, credentials)
        .toPromise()
        .then((res) => {
          let user = new AuthUser(res);
          // check the whitelist

          if (environment.auth.roles && environment.auth.roles.whitelist) {
            let winter = _.intersection(
              environment.auth.roles.whitelist,
              user.roles
            );
            if (winter.length == 0) {
              return reject({ error: 'not_authorized' });
            }
          }
          if (environment.auth.roles && environment.auth.roles.blacklist) {
            let binter = _.intersection(
              environment.auth.roles.blacklist,
              user.roles
            );
            if (binter.length > 0) {
              return reject({ error: 'not_authorized' });
            }
          }
          if (user.exists() && user.access_token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(res));
            this.currentAuthUserSubject.next(user);
            this.tokenRefreshedSubject.next(user.access_token);
            this.authenticated = true;
            resolve(true);
          } else {
            reject({ error: 'authentication_error' });
          }
        })
        .catch((err) => {
          console.log('Error login', err);
          reject(err.error || 'Server error');
        });
    });
  }

  public loginWithOtp(token, params = {}) {
    const url = this.api_root + '/auth/loginotp';
    let p = {
      token: token,
    };

    p = { ...p, ...params };
    return new Promise((resolve, reject) => {
      this.http
        .post(url, p)
        .toPromise()
        .then((res) => {
          let user = new AuthUser(res);
          // check the whitelist

          if (environment.auth.roles && environment.auth.roles.whitelist) {
            let winter = _.intersection(
              environment.auth.roles.whitelist,
              user.roles
            );
            if (winter.length == 0) {
              return reject({ error: 'not_authorized' });
            }
          }
          if (environment.auth.roles && environment.auth.roles.blacklist) {
            let binter = _.intersection(
              environment.auth.roles.blacklist,
              user.roles
            );
            if (binter.length > 0) {
              return reject({ error: 'not_authorized' });
            }
          }
          if (user.exists() && user.access_token) {
            // remove tempPlayer from storage
            localStorage.removeItem('RSplayer');

            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(res));
            this.currentAuthUserSubject.next(user);
            this.tokenRefreshedSubject.next(user.access_token);
            this.authenticated = true;
            resolve(true);
          } else {
            reject({ error: 'authentication_error' });
          }
        })
        .catch((err) => {
          console.log('Error login', err);
          reject(err.error || 'Server error');
        });
    });
  }

  public askForOtp(credentials) {
    const url = this.api_root + '/auth/otp';
    return new Promise((resolve, reject) => {
      this.http
        .post(url, credentials)
        .toPromise()
        .then((res) => {
          resolve(true);
        })
        .catch((err) => {
          console.log('Error login', err);
          reject(err.error || 'Server error');
        });
    });
  }

  public registerWithEmail(credentials, type = 'otp') {
    let url = this.api_root + '/auth/register';
    if (type == 'otp') {
      url = this.api_root + '/auth/registerotp';
    }

    return new Promise((resolve, reject) => {
      this.http
        .post(url, credentials)
        .toPromise()
        .then((res) => {
          let user = new AuthUser(res);

          // check the whitelist

          if (environment.auth.roles && environment.auth.roles.whitelist) {
            let winter = _.intersection(
              environment.auth.roles.whitelist,
              user.roles
            );
            if (winter.length == 0) {
              return reject({ error: 'not_authorized' });
            }
          }
          if (environment.auth.roles && environment.auth.roles.blacklist) {
            let binter = _.intersection(
              environment.auth.roles.blacklist,
              user.roles
            );
            if (binter.length > 0) {
              return reject({ error: 'not_authorized' });
            }
          }
          // if (user.exists() && user.access_token) {
          //   // store user details and jwt token in local storage to keep user logged in between page refreshes
          //   localStorage.setItem('currentUser', JSON.stringify(user));
          //   this.currentAuthUserSubject.next(user);
          //   this.authenticated = true;
          //   resolve(true);
          // } else {
          //   reject({ error: "authentication_error" });
          // }

          resolve(res);
        })
        .catch((err) => {
          console.log('Error login', err);
          reject(this.parseErrors(err));
        });
    });
  }

  public checkVerification(mode, token) {
    const url = this.api_root + '/auth/verification';

    return new Promise((resolve, reject) => {
      this.http
        .post(url, { mode: mode, token: token })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log('Error checkVerification', err);
          reject(this.parseErrors(err));
        });
    });
  }

  public logout(redirect = '/') {
    const url = this.api_root + '/auth/logout';
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.getCurrentAuthUser().access_token,
    });
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');

    // remove temp user from local storage
    localStorage.removeItem('RSplayer');
    this.authenticated = false;
    this.currentAuthUserSubject.next(null);
    if (this._tokenInterval) {
      clearInterval(this._tokenInterval);
    }

    this.http
      .get(url, { headers: header })
      .toPromise()
      .then((res) => {
        if (redirect) {
          window.location.href = redirect;
        }
      })
      .catch((err) => {
        if (redirect) {
          window.location.href = redirect;
        }
      });
  }

  public getCurrentAuthUser(): AuthUser {
    return this.currentAuthUserSubject.value;
  }

  public refreshToken(): Promise<AuthUser> {
    const url = this.api_root + '/auth/refresh';
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.getCurrentAuthUser().access_token,
    });

    return new Promise((resolve, reject) => {
      this.http
        .post(url, {}, { headers: header })
        .toPromise()
        .then((res) => {
          let user = new AuthUser(res);
          if (
            user.exists() &&
            user.access_token &&
            user.roles &&
            user.roles.length > 0
          ) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentAuthUserSubject.next(user);
            this.tokenRefreshedSubject.next(user.access_token);

            this.authenticated = true;
            resolve(user);
          } else {
            reject('error_refresh_token');
          }
        })
        .catch((err) => {
          console.log('Error refresh token', err);
          reject(err.error || 'Server error');
        });
    });
  }

  public getCurrentUser(): User {
    return this.currentUserSubject ? this.currentUserSubject.value : null;
  }

  public loadCurrentUser(set_temp_user: boolean = false) {
    return new Promise((resolve, reject) => {
      this.data
        .getAsPromise('auth/me?ts=' + new Date().getTime())
        .then((res) => {
          if (res && res.data) {
            let user = new User(res.data);
            this.currentUserSubject.next(user);
            resolve(user);
          } else {
            reject('not_authenticated');
            this.logout();
          }
        })
        .catch((err) => {
          console.log('Error ', err);
          reject(err);
        });
    });
  }

  autorefreshToken() {
    if (!this._tokenInterval) {
      this._tokenInterval = setInterval(() => {
        if (this.currentAuthUserSubject.value) {
          const token = this.currentAuthUserSubject.value.access_token;

          const helper = new JwtHelperService();
          const expirationDate = helper.getTokenExpirationDate(token);
          const isExpired = helper.isTokenExpired(token);

          if (isExpired) {
            this.logout();
            return false;
          }
          let delta = moment(expirationDate).diff(moment().utc(), 'seconds');
          if (delta <= this._tokenIntervalDuration) {
            this.refreshToken();
          }
        }
        return true;
      }, this._tokenIntervalDuration * 1000);
    }
  }

  updateAccount(id, params) {
    return new Promise((resolve, reject) => {
      const url = `auth/me/${id}`;
      this.data
        .putAsPromise(url, params)
        .then((res) => {
          if (res.data) {
            resolve(res.data);
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          console.log('Error delete account', err);
          reject(err);
        });
    });
  }

  deleteAccount() {
    const url = 'auth/delete-account';
    return this.data
      .deleteAsPromise(url)
      .then((res) => {
        // this.logout();

        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');

        // remove temp user from local storage
        localStorage.removeItem('RSplayer');
        this.authenticated = false;
        this.currentAuthUserSubject.next(null);
        if (this._tokenInterval) {
          clearInterval(this._tokenInterval);
        }

        window.location.href = '/';
      })
      .catch((err) => {
        console.log('Error delete account', err);
      });
  }

  parseErrors(data) {
    let ret = data.error;
    ret.fields = {};
    if (data.error.errors && data.error.errors.length > 0) {
      data.error.errors.forEach((err) => {
        ret.fields[err.field] = err.code;
      });
    }
    return ret;
  }

  hasRole(roles = []) {
    let u = this.getCurrentAuthUser();
    if (!u || !u.exists()) {
      return false;
    }
    return u.hasRole(roles);
  }

  hasPermission(perms = []) {
    let u = this.getCurrentAuthUser();
    if (!u || !u.exists()) {
      return false;
    }
    return u.hasPermission(perms);
  }

  getTempPlayer() {
    // let tempPlayer: any = localStorage.getItem('tempPlayer');
    // if (tempPlayer) {
    //   tempPlayer = new TempPlayer(JSON.parse(tempPlayer));
    //   return tempPlayer;
    // }
    return null;
  }

  setTempPlayerGame(userGame) {
    // console.log('setTempPlayerGame', userGame)
    // let tempPlayer = this.getTempPlayer();
    // if (tempPlayer) {
    //   tempPlayer['userGame'] = userGame;
    // } else {
    //   tempPlayer = new TempPlayer({userGame:userGame});
      
    // }

    // localStorage.setItem('tempPlayer', JSON.stringify(tempPlayer));
  }

  setTempPlayerGameOver(game) {
    // let tempPlayer = this.getTempPlayer();
    // game['date'] = moment().format('Y-MM-DD');

    // if (tempPlayer) {
    //   let gamesOver = tempPlayer.gamesOver;
    //   if (gamesOver) {
    //     let o = _.find(gamesOver, {
    //       game_id: game.game_id,
    //       category_id: game.category_id,
    //     });
    //     if (!o) {
    //       gamesOver.push(game);
    //     }
    //   } else {
    //     gamesOver = [game];
    //   }
    //   tempPlayer['gamesOver'] = gamesOver;
    // }

    // localStorage.setItem('tempPlayer', JSON.stringify(tempPlayer));
  }

  removeUserGameOver(game) {
    // let tempPlayer = this.getTempPlayer();
    // if (tempPlayer) {
    //   let gamesOver = tempPlayer.gamesOver;
    //   if (gamesOver) {
    //     let index = _.findIndex(gamesOver, {
    //       game_id: game.game_id,
    //       category_id: game.category_id,
    //     });
    //     if (index !== -1) {
    //       gamesOver.splice(index, 1);
    //     }
    //   }
    //   tempPlayer['gamesOver'] = gamesOver;
    // }

    // localStorage.setItem('tempPlayer', JSON.stringify(tempPlayer));
  }

  removeUserGamesOver() {
    // let tempPlayer = this.getTempPlayer();
    // if (tempPlayer) {
    //   tempPlayer['gamesOver'] = null;
    // }

    // localStorage.setItem('tempPlayer', JSON.stringify(tempPlayer));
  }

  setTempPlayerAttributes(attributes) {
    // let tempPlayer = this.getTempPlayer();
    // if (tempPlayer) {
    //   for (let a in attributes) {
    //     tempPlayer[a] = attributes[a];
    //   }
    // } else {
    //   tempPlayer = { ...attributes };
    // }

    // localStorage.setItem('tempPlayer', JSON.stringify(tempPlayer));
  }

  setCurrentUser(user) {
    this.currentUserSubject.next(user);
  }

  public signInWithApple(params=null) {
    return new Promise((resolve, reject) => {
      let options: SignInWithAppleOptions =
        environment.auth.providers.apple.options;
      SignInWithApple.authorize(options)
        .then((resp: SignInWithAppleResponse) => {
          console.log('resp', resp);

          if (!resp || !resp.response) {
            return reject({ error: 'authentication_error' });
          }
          const credentials = params || {};
          credentials.user = resp.response;
          

          const url = 'auth/siwa';

          this.data
            .postAsPromise(url, credentials)
            .then((res) => {
              let user = new AuthUser(res);
              // check the whitelist

              if (environment.auth.roles && environment.auth.roles.whitelist) {
                let winter = _.intersection(
                  environment.auth.roles.whitelist,
                  user.roles
                );
                if (winter.length == 0) {
                  return reject({ error: 'not_authorized' });
                }
              }
              if (environment.auth.roles && environment.auth.roles.blacklist) {
                let binter = _.intersection(
                  environment.auth.roles.blacklist,
                  user.roles
                );
                if (binter.length > 0) {
                  return reject({ error: 'not_authorized' });
                }
              }
              if (user.exists() && user.access_token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(res));
                this.currentAuthUserSubject.next(user);
                this.tokenRefreshedSubject.next(user.access_token);
                this.authenticated = true;
                return resolve(true);
              }
              return reject({ error: 'authentication_error' });
            })
            .catch((err) => {
              console.log('Error login', err);
              return reject(err.error || 'Server error');
            });
        })
        .catch((e) => {
          console.log('error', e);
          return reject({ error: 'authentication_error' });
        });
    });
  }

  public signInWithGoogle(params=null) {
    return new Promise((resolve, reject) => {
      return GoogleAuth.signIn()
        .then((google_user) => {
          console.log('google_user', google_user);

          if (
            google_user &&
            google_user.authentication &&
            google_user.authentication.accessToken
          ) {
            const credentials = params || {};
            credentials.accessToken =  google_user.authentication.accessToken;

            const url = 'auth/siwg';

            this.data
              .postAsPromise(url, credentials)
              .then((res) => {
                let user = new AuthUser(res);
                // check the whitelist

                if (
                  environment.auth.roles &&
                  environment.auth.roles.whitelist
                ) {
                  let winter = _.intersection(
                    environment.auth.roles.whitelist,
                    user.roles
                  );
                  if (winter.length == 0) {
                    return reject({ error: 'not_authorized' });
                  }
                }
                if (
                  environment.auth.roles &&
                  environment.auth.roles.blacklist
                ) {
                  let binter = _.intersection(
                    environment.auth.roles.blacklist,
                    user.roles
                  );
                  if (binter.length > 0) {
                    return reject({ error: 'not_authorized' });
                  }
                }
                if (user.exists() && user.access_token) {
                  // store user details and jwt token in local storage to keep user logged in between page refreshes
                  localStorage.setItem('currentUser', JSON.stringify(res));
                  this.currentAuthUserSubject.next(user);
                  this.tokenRefreshedSubject.next(user.access_token);
                  this.authenticated = true;
                  return resolve(true);
                }
                return reject({ error: 'authentication_error' });
              })
              .catch((err) => {
                console.log('Error login', err);
                return reject(err.error || 'Server error');
              });
          } else {
            return reject({ error: 'authentication_error' });
          }
        })
        .catch((e) => {
          console.log('error', e);
          return reject({ error: 'authentication_error' });
        });
    });
  }
}
