import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { isPlatform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DataService } from 'src/app/services/data.service';
import { environment } from './../../../../environments/environment';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loader = false;
  public show_2fa = false;
  public show_register_form: boolean = true;
  public success_register_form: boolean = null;
  public show_forgot: boolean = false;
  public show_email_field: boolean = true;
  public show_button_send: boolean = true;
  public send_email = null;
  public showLoginWithOTPForm: boolean = false;
  public platform: string = 'web';

  protected server_errors = null;

  loginWithEmailForm = this.fb.group({
    email: new UntypedFormControl(
      '',
      Validators.compose([Validators.required, Validators.email])
    ),
  });

  loginWithOTPForm = this.fb.group({
    token: new UntypedFormControl(
      '',
      Validators.compose([Validators.required])
    ),
  });

  registerWithEmailForm = null;

  login2FAForm = this.fb.group({
    authcode: new UntypedFormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6),
      ])
    ),
  });

  loginWithPhoneForm = this.fb.group({
    phone: new UntypedFormControl(
      '',
      Validators.compose([Validators.required])
    ),
    code: new UntypedFormControl(''),
  });
  method = null;
  captcha_valid = false;
  providers = {};
  show_providers = false;
  show_register = false;
  error_msg = null;
  isProd = false;
  next_url = null;
  public loginMode: string = null;

  @Input() showBackBtn: boolean = true;
  @Input() set extraParams(p) {
    this._extraParameters = p;
  }
  private _extraParameters = {};
  @Input() withRouteChange: boolean = true;
  @Input() showLogo: boolean = true;
  @Input() title: String = null;
  @Input() showRegisterBtn: boolean = true;
  @Output() onGoToRegister: EventEmitter<any> = new EventEmitter();
  @Output() afterLogin: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: UntypedFormBuilder,
    public auth: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private data: DataService,
    private location: Location,
    private analytics: AnalyticsService

  ) {
    this.platform = Capacitor.getPlatform();

    this.route.queryParams.subscribe((params) => {
      if (params && params['next_url']) {
        this.next_url = params['next_url'];
      }
    });

    this.route.paramMap.subscribe((params) => {
      const mode = params.get('mode');
      if (mode) {
        this.loginMode = mode;
      } else {
        this.loginMode = null;
      }
    });

    let password = new UntypedFormControl(
      '',
      Validators.compose([Validators.required])
    );
    let confirmPassword = new UntypedFormControl(
      '',
      Validators.compose([Validators.required])
    );

    this.registerWithEmailForm = new UntypedFormGroup({
      firstname: new UntypedFormControl('', [Validators.required]),
      lastname: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl(
        '',
        Validators.compose([Validators.required, Validators.email])
      ),
    });

    this.isProd = environment.production;

    if (environment['auth'] && environment['auth'].providers) {
      for (let p in environment['auth'].providers) {
        if (environment['auth'].providers[p].enabled) {
          this.providers[p] = environment['auth'].providers[p];
        }
      }
    } else {
      this.providers = { email: { enabled: true } };
    }

    let keys = Object.keys(this.providers);
    this.show_providers =
      keys.indexOf('google') >= 0 ||
      keys.indexOf('facebook') >= 0 ||
      keys.length > 1;
    if (keys.length === 1 && keys[0] != 'google' && keys[0] != 'facebook') {
      this.method = keys[0];
    }
  }

  ngOnInit() {
    if (this.withRouteChange) {
      this.changeRoute();
    }
    if (!isPlatform('capacitor')) {
      GoogleAuth.initialize();
    }
  }

  changeRoute() {
    if (this.loginMode == 'usergame') {
      this.next_url = '/quizz';
    }
    if (this.auth.authenticated) {
      if (this.next_url) {
        this.router.navigateByUrl(this.next_url);
      } else {
        this.router.navigate(['/']);
      }
    }
  }

  public onLoginWithEmailSubmit(event, credentials) {
    this.error_msg = null;
    this.loginWithEmailForm.markAllAsTouched();
    if (!this.loginWithEmailForm.valid) {
      return null;
    }

    this.loader = true;
    this.method = 'email';
    this.auth
      .askForOtp(credentials)
      .then((res) => {
        this.loader = false;

        // show login with token
        this.showLoginWithOTPForm = true;
      })
      .catch((error) => {
        this.loader = false;
        this.error_msg = this.translate.instant('STATIC.ERRORS.' + error.error);
      });
  }

  public onLoginWithOTPSubmit(event) {
    this.error_msg = null;
    this.loginWithOTPForm.markAllAsTouched();
    let params = {};
    if (this.loginMode == 'usergame') {
      const tempPlayer = this.auth.getTempPlayer();
      if (tempPlayer && tempPlayer.userGame) {
        params = {
          user_game: tempPlayer.userGame,
        };
      }
    }

    params = { ...params, ...this._extraParameters };
    if (!this.loginWithOTPForm.valid) {
      return null;
    }

    this.loader = true;
    let data = this.loginWithOTPForm.value;
    this.auth
      .loginWithOtp(data.token, params)
      .then((res) => {
        this.afterLogin.emit({ success: true, params: params });
        this.loader = false;
        this.analytics.logEvent('login', {method:'otp'});
        // go to page
        if (this.withRouteChange) {
          this.changeRoute();
        }
      })
      .catch((error) => {
        this.loader = false;
        this.analytics.logEvent('login_error', {method:'otp'});

        if (error.error == '2fa_enabled') {
          this.show_2fa = true;
          this.error_msg = null;
        } else {
          this.error_msg = this.translate.instant(
            'STATIC.ERRORS.' + error.error
          );
        }
        this.afterLogin.emit({ success: false, params: params });
      });
  }

  public cancel2fa() {
    this.login2FAForm.reset();
    this.show_2fa = false;
    this.error_msg = null;
  }

  onLoginWithEmail2faSubmit(event, credentials, authcode) {
    if (this.login2FAForm.valid && this.loginWithEmailForm.valid) {
      this.loader = true;
      this.method = 'email';
      credentials.authcode = authcode.authcode;
      this.auth
        .loginWithEmail(credentials)
        .then((res) => {
          this.loader = false;
          this.analytics.logEvent('login_otp');
        })
        .catch((error) => {
          this.loader = false;
          this.error_msg = this.translate.instant(
            'STATIC.ERRORS.' + error.error
          );
        });
    }
  }

  signIn(mode) {}

  forgotPassword() {}

  onLoginWithPhoneSubmit() {}

  goToRegister() {
    this.onGoToRegister.emit(true);
    // this.error_msg = null;
    // this.show_register = true;
  }

  goToLogin() {
    this.show_register = false;
    this.show_forgot = false;
    this.show_register_form = true;
    this.showLoginWithOTPForm = false;
    this.loader = false;
    this.registerWithEmailForm.reset();
    this.registerWithEmailForm.clearValidators();
    this.registerWithEmailForm.updateValueAndValidity();
  }

  onBack() {
    this.show_email_field = true;
    this.show_button_send = true;
    this.show_forgot = false;
    this.send_email = null;
    this.loader = false;
  }

  setServerErrors(error) {
    this.server_errors = error;
    for (let i in error.errors) {
      this.registerWithEmailForm.controls[i].setErrors({ serverError: true });
    }
  }

  getServerError(controlName: string) {
    if (
      this.server_errors &&
      this.server_errors.errors &&
      this.server_errors.errors[controlName]
    ) {
      return this.translate.instant(
        'STATIC.ERRORS.' +
          this.server_errors.errors[controlName].toString().replace(/\./gi, '')
      );
    }
    return null;
  }

  goBackToPrevPage(): void {
    this.location.back();
  }

  signInAppleNative() {
    console.log('SignInApple');
    this.loader = true;
    this.method = 'google';
    let params = {};
    if (this.loginMode == 'usergame') {
      const tempPlayer = this.auth.getTempPlayer();
      if (tempPlayer && tempPlayer.userGame) {
        params = {
          user_game: tempPlayer.userGame,
        };
      }
    }

    this.auth
      .signInWithApple(params)
      .then((res) => {
        console.log('USER LOGGED', res);
        this.analytics.logEvent('login', {method:'apple'});
        this.afterLogin.emit({ success: true, params: params });
        this.loader = false;
        this.method = 'email';
        // go to page
        if (this.withRouteChange) {
          this.changeRoute();
        }
      })
      .catch((e) => {
        console.log('error', e);
        this.loader = false;
        this.method = 'email';
      });
  }

  async signInGoogle() {
    this.loader = true;
    this.method = 'google';
    let params = {};
    // if (this.loginMode == 'usergame') {
    //   const tempPlayer = this.auth.getTempPlayer();
    //   if (tempPlayer && tempPlayer.userGame) {
    //     params = {
    //       user_game: tempPlayer.userGame,
    //     };
    //   }
    // }

    params = { ...params, ...this._extraParameters };

    this.auth
      .signInWithGoogle(params)
      .then((res) => {
        console.log('USER LOGGED', res);
        this.analytics.logEvent('login', {method:'google'});
        this.afterLogin.emit({ success: true, params: params });
        this.loader = false;
        this.method = 'email';
        // go to page
        if (this.withRouteChange) {
          this.changeRoute();
        }
      })
      .catch((e) => {
        console.log('error', e);
        this.loader = false;
        this.method = 'email';
      });
  }
}
