import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CmsService } from 'src/app/services/cms.service';
import Player from '@vimeo/player';

@Component({
  selector: 'cms-block-video',
  templateUrl: './video-block.component.html',
  styleUrls: ['./video-block.component.scss'],
})
export class VideoBlockComponent implements OnInit {
  public ready: boolean = false;

  @Input() block = null;
  @ViewChild('vimeoVideo', { static: false }) vimeoVideo: ElementRef;
  @ViewChild('wrapperVideo', { static: false }) wrapperVideo: ElementRef;

  public content_id: string = null;

  //  private youtubePlayer: YT.Player;
  private vimeoPlayer: Player;
  public videoPoster: string = null;
  public videoPlaying: boolean = false;
  public loadingVimeo: boolean = false;
  public videoId: string = null;
  public width = null;
  public height = null;


  constructor(
    public cms: CmsService,
    protected modalController: ModalController
  ) {}

  ngOnInit() {
    switch (this.block.data.player) {
      case 'vimeo':
        this.videoId = this.getVimeoId(this.block.data.src);
        this.initVimeo();
        break;
      case 'youtube':
        this.videoId = this.getYoutubeId(this.block.data.src);
        this.initYoutube();

        break;
      default:
    }
  }

  toggleVideo(disabled = false) {
    switch (this.block.data.player) {
      case 'vimeo':
        this.toggleVimeo(disabled);
        break;
      default:
    }
  }

  stopVideo() {
    switch (this.block.data.player) {
      case 'vimeo':
        this.stopVimeo();
        break;
      default:
    }
  }

  initVimeo() {
    this.loadingVimeo = true;

    setTimeout(() => {
      const w = this.vimeoVideo.nativeElement.offsetWidth;
      const h = this.vimeoVideo.nativeElement.offsetHeight;

      this.vimeoPlayer = new Player(this.vimeoVideo.nativeElement, {
        id: this.videoId,
        autopause: 0,
        autoplay: 0,
        byline: 0,
        controls: 0,
        loop: 0,
        muted: 0,
        portrait: 0,
        title: 0,
        width: w,
        height: h,
      });
    }, 100);
  }

  toggleVimeo(disabled = false) {
    if (disabled) {
      return Promise.resolve(false);
    }
    return this.vimeoPlayer.getPaused().then((paused: boolean) => {
      if (paused) {
        this.vimeoPlayer.play();
        this.videoPlaying = true;
        return Promise.resolve(true);
      } else {
        this.vimeoPlayer.pause().then(() => {
          this.videoPlaying = false;
          return Promise.resolve(false);
        });
        return false;
      }
    });
    event.stopPropagation();
  }

  stopVimeo() {
    console.log('stopVimeo');
    if (this.videoPlaying) {
      return this.toggleVimeo();
    }
  }

  getVimeoId(url) {
    const regex = /(?:vimeo)\.com.*\/(\d+)/i;
    const match = url.match(regex);
    return match ? match[1] : null;
  }

  initYoutube() {
    const tag = document.createElement('script');

    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    this.ready = true;

    setTimeout(() => {
      this.width = this.wrapperVideo.nativeElement.offsetWidth;

      this.height = this.wrapperVideo.nativeElement.offsetHeight;

      this.height = this.width/16*9;

      console.log('Init youtube', this.width, this.height);

    }, 200);
  }

  getYoutubeId(url) {
    const regExp =
      /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
    const match = url.match(regExp);

    if (match && match[2]) {
      return match[2];
    } else {
      // Invalid YouTube URL or video ID not found
      return null;
    }
  }

  saveYoutubePlayer(player) {
    //this.youtubePlayer = player
  }
  onYoutubeChange(event) {
    console.log('player state', event.data);
  }

  toggleYoutube(disabled = false) {
    // this.youtubePlayer.playVideo();
  }
}
