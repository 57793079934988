<div class="layout-container" [class.desktop]="isDesktop">
  <div class="content-wrapper" [class.desktop]="isDesktop">
    <div class="router-outlet-container">
      <ion-menu class="slide-menu" side="start" contentId="main-content">
        <ion-header no-border>
          <img
            class="logo hand"
            src="/assets/images/logo_white.png"
            (click)="goHome($event)"
          />
        </ion-header>
        <ion-content no-border  class="app-background">
          <div class="menu-rs menu-block" *ngIf="menu_side">
            <!-- <h2>{{ "NAV.MENU_RS" | translate }}</h2> -->
            <ion-list>
              <ng-container *ngFor="let item of menu_side">
                <ion-item
                  *ngIf="!item.is_separator"
                  detail="false"
                  lines="none"
                  (click)="onMenuClick(item)"
                  class="hand"
                  ><ion-label>{{ item.title }}</ion-label>
                  <ion-icon
                    *ngIf="item.media_url"
                    [name]="item.media_url"
                    slot="end"
                  ></ion-icon
                ></ion-item>
                <div *ngIf="item.is_separator" class="separator"></div>
              </ng-container>
            </ion-list>
          </div>

          <div class="menu-account menu-block" *ngIf="menu_account">
            <!-- <h2>{{ "NAV.MENU_ACCOUNT" | translate }}</h2> -->
            <ion-list>
              <ng-container *ngFor="let item of menu_account">
                <ion-item
                  *ngIf="!item.is_separator"
                  detail="false"
                  lines="none"
                  (click)="onMenuClick(item)"
                  class="hand"
                  ><ion-label>{{ item.title }}</ion-label>
                  <ion-icon
                    *ngIf="item.media_url"
                    [name]="item.media_url"
                    slot="end"
                  ></ion-icon
                ></ion-item>
                <div *ngIf="item.is_separator" class="separator"></div>
              </ng-container>
            </ion-list>
          </div>
          <div class="menu-socials menu-block" *ngIf="menu_socials">
            <ion-icon
              *ngFor="let item of menu_socials"
              [name]="item.media_url"
              (click)="onMenuClick(item)"
              class="hand"
            ></ion-icon>
          </div>
        </ion-content>
        <ion-footer class="ion-no-border" color="primary">
          <div class="sponsor">
            <div>{{ 'APP.POWERED_BY'|translate}}</div>
            <img class="bcr-logo" src="/assets/images/BCR_logo.svg" />
          </div>
          <!-- <div class="lang-toolbar">
            <ion-button size="small" (click)="cms.changeLang('ro')"
              >RO</ion-button
            >
            /
            <ion-button size="small" (click)="cms.changeLang('en')"
              >EN</ion-button
            >
          </div> -->
        </ion-footer>
      </ion-menu>

      <ion-router-outlet [animated]="false" id="main-content">
      </ion-router-outlet>
    </div>
  </div>
</div>
<audio #myAudio loop></audio>
