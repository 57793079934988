

export class Avatar {
  id: number | null = null;
  code:string = null;
  

  constructor(id?: number | Object ) {
    if (typeof id == 'object') {
      this.import(id);
    } else {
      this.id = id as number | null;
    }
  }

  exists() {
    return !!this.id;
  }

  import(data: any) {
    for (var key in data) {
      if (this.hasOwnProperty(key)) {
        switch (key) {
          default:
            this[key as keyof this] = data[key];
        }
      }
    }
  }
}

