import { Component, Input, OnInit } from '@angular/core';
import { CmsService } from 'src/app/services/cms.service';

@Component({
  selector: 'cms-block-text',
  templateUrl: './text-block.component.html',
  styleUrls: ['./text-block.component.scss'],
})
export class TextBlockComponent implements OnInit {
  public ready: boolean = false;
  public content_id: string = null;

  @Input() block = null;

  constructor(public cms: CmsService) {}

  ngOnInit() {}
}
