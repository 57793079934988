<div class="wrapper {{ anim }}">
  <img *ngIf="img" [src]="img" class="size {{ size }}" />

  <ion-spinner
    *ngIf="!img"
    [name]="icon"
    [color]="color"
    class="size {{ size }} "
  ></ion-spinner>
</div>
