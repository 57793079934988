import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Device } from '@capacitor/device';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor() {
    console.log('Aanalytics initialize');
    if(environment.firebase && environment['analytics'] && environment['analytics'].enabled) {

      this.initAnalytics();
      }
  }

  async initAnalytics() {
    if ((await Device.getInfo()).platform == 'web') {
      FirebaseAnalytics.initializeFirebase(environment.firebase);
    }
  }

  setScreenName(screenName, nameOverride) {
    console.log('ANALYTICS.setScreenName',screenName,  nameOverride)
    FirebaseAnalytics.setScreenName({
      screenName: screenName,
      nameOverride: nameOverride,
    });
  }

  logEvent(name, params = {}) {
    console.log('ANALYTICS.logEvent',name)
    FirebaseAnalytics.logEvent({
      name: name,
      params: params,
    });
  }
}
