<!-- <ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" >Cancel</ion-button>
    </ion-buttons>
    <ion-title>Welcome</ion-title>
    <ion-buttons slot="end">
      <ion-button  [strong]="true">Confirm</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header> -->
<ion-content class="ion-padding modal-wrapper app-background" [hidden]="!showModal">
  <div class="modal-content">
    <ion-fab class="cms-fab" slot="fixed" vertical="top" horizontal="end">
      <ion-fab-button (click)="hideModal('cancel')">
        <ion-icon name="close"></ion-icon>
      </ion-fab-button>
    </ion-fab>

    <div *ngIf="showLogin">
      <app-login
        [showBackBtn]="false"
        [extraParams]="authExtraParams"
        [withRouteChange]="false"
        (onGoToRegister)="onGoToRegister($event)"
        (afterLogin)="afterLogin($event, 'login')"
      ></app-login>
    </div>

    <div *ngIf="showRegister">
      <app-register
        [showBackBtn]="false"
        [extraParams]="authExtraParams"
        [withRouteChange]="false"
        (onGoToLogin)="onGoToLogin($event)"
        (afterLogin)="afterLogin($event, 'register')"
      ></app-register>
    </div>

    <div *ngIf="this.showGameMessage">
      {{ "AUTH.GAME_ALREADY_EXISTS_MESSAGE" | translate }}
      <div class="ion-text-center">
        <ion-button fill="outline" (click)="continue('similar-game')">{{
          "AUTH.CONTINUE_SIMILAR_GAME_BT" | translate
        }}</ion-button>
        <ion-button fill="outline" (click)="continue('current-game')">{{
          "AUTH.CONTINUE_CURRENT_GAME_BT" | translate
        }}</ion-button>
        <ion-button fill="outline" (click)="continue('new-game')">{{
          "AUTH.START_NEW_GAME" | translate
        }}</ion-button>
      </div>
    </div>
  </div>
</ion-content>
