import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cms-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss'],
})
export class PageFooterComponent implements OnInit {

  @Input() src : string = null;
  
  constructor(
  ) {
  }

  ngOnInit() {}
}
