import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import {
  ModalController,
  GestureController,
} from '@ionic/angular';
import * as _ from 'lodash';
import Player from '@vimeo/player';
import { Device } from '@capacitor/device';
import {Swiper} from "swiper";
import { register } from 'swiper/element/bundle';

import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-gallery-viewer',
  templateUrl: './gallery-viewer.component.html',
  styleUrls: ['./gallery-viewer.component.scss'],
})
export class GalleryViewerComponent implements OnInit {
  @ViewChild('slider') slider: ElementRef;
  swiper : Swiper;
  public img_path: string = environment.fileserver.images;
  public _slides = [];

  @Input() imgSource = null;
  @Input() vimeoSource = null;
  @Input() imgTitle = '';
  @Input() imgDescription = '';
  @Input() gallery = [];
  @Input() imgIdx:number  = 0;
  @Input() rotate: boolean = false;

  public isLandscape: boolean = true;
  public videoPlaying: boolean = false;
  public isDesktop : boolean = false;
  public direction= 'vertical';

  slideOpts = {
    centeredSlides: 'true',
    //pager: 'true',
    initialSlide: this.imgIdx,
    direction:'vertical'
  };

  constructor(
    private modalController: ModalController,
    private gestureCtrl: GestureController,
    private ref: ChangeDetectorRef,
  ) {
    register();
    
    Device.getInfo().then((info)=>{
      this.isDesktop = info.platform==='web';
      this.direction= this.isDesktop? 'horizontal': 'vertical';
    })

  }

  swiperReady() {
    console.log('isReady')
    this.swiper = this.slider.nativeElement.swiper;
  }

  nextSlide() {
    this.slider.nativeElement.swiper.slideNext()
  }

  previousSlide() {
    this.slider.nativeElement.swiper.slidePrev();
  }

  ngOnInit() {

      
   
    this.slideOpts.initialSlide = this.imgIdx;

    if (this.imgSource && this.gallery.length == 0) {
      this.gallery.push({ url: this.imgSource });
    }
    this._slides = _.concat(_.slice(this.gallery, this.imgIdx), _.slice(this.gallery, 0, this.imgIdx));

  }

  getVimeoId(url) {
    const regex = /(?:vimeo)\.com.*\/(\d+)/i;
    const match = url.match(regex);
    return match ? match[1] : null;
  }

  async ngAfterViewInit() {
    debugger
    // init vimeo
    this.gallery.forEach((item, index) => {
      
      if (item.url.indexOf('://')===-1 && item.url.indexOf('data:')) {
        item.url = this.img_path+'/'+item.url;
      }  
    
      const vimeoId = this.getVimeoId(item.url);
      const myElement = document.getElementById(`viewer-vimeo-player-${index}`);
      if (vimeoId && myElement) {
        setTimeout(() => {
          const w = myElement.offsetWidth;
          const h = myElement.offsetHeight;

          console.log('vimeoId', vimeoId, w, h, myElement);
          const player = new Player(`viewer-vimeo-player-${index}`, {
            id: vimeoId,
            autopause: 0,
            autoplay: 0,
            byline: 0,
            controls: 0,
            loop: 0,
            muted: 0,
            portrait: 0,
            title: 0,
            responsive: true,
            //  width: w,
            //  height:h,
          });
          item.vimeo = player;
        }, 300);
      }
    });
  }

  async onSlideChanged(event) {
    console.log('onSlideChanged');
    const currentIndex = await this.slider.nativeElement.swiper.activeIndex;

    console.log('currentIndex', currentIndex);
  }

  closeModal() {
    this.modalController.dismiss();
  }


  onImageLoad(event, img) {
    const imgElement = event.target as HTMLImageElement;
    const width = imgElement.clientWidth;
    const height = imgElement.clientHeight;

    console.log(
      'onImageLoad',
      width,
      height,
      width > height,
      imgElement.naturalWidth,
      imgElement.naturalHeight,
      imgElement.naturalWidth > imgElement.naturalHeight,
      width > height || imgElement.naturalWidth > imgElement.naturalHeight
    );

    //img.landscape = imgElement.naturalWidth > imgElement.naturalHeight;

    img.landscape =
      width > height || imgElement.naturalWidth > imgElement.naturalHeight;
  }

  async playVimeo() {
    const currentIndex = await this.slider.nativeElement.swiper.activeIndex;
    console.log('currentIndex', currentIndex);

    if (
      this.gallery[currentIndex] &&
      this.gallery[currentIndex].type === 'video'
    ) {
      // play video
      console.log('play video');
    }
  }

  async toggleVimeo() {
    const currentIndex = await this.slider.nativeElement.swiper.activeIndex;
    console.log('currentIndex', currentIndex);

    if (this.gallery[currentIndex] && this.gallery[currentIndex].vimeo) {
      // play video
      console.log('toggle video');
      const player = this.gallery[currentIndex].vimeo;

      player.getPaused().then((paused: boolean) => {
        if (paused) {
          player.play();
          this.videoPlaying = true;
        } else {
          player.pause();
          this.videoPlaying = false;
        }
      });
    }
  }

  async fullscreenVideo() {
    const currentIndex = await this.slider.nativeElement.swiper.activeIndex;
    console.log('currentIndex', currentIndex);

    if (this.gallery[currentIndex] && this.gallery[currentIndex].vimeo) {
      // play video
      const player = this.gallery[currentIndex].vimeo;

      if (player.requestFullscreen) {
        player.requestFullscreen();
      } else if (player.webkitRequestFullscreen) {
        player.webkitRequestFullscreen();
      } else if (player.msRequestFullscreen) {
        player.msRequestFullscreen();
      }
    }
  }
}
