import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AuthUser } from 'src/app/models/AuthUser';
import { UserGame } from 'src/app/modules/quizz/models/UserGame';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'rs-auth-modal',
  templateUrl: './auth-modal.component.html',
  styleUrls: ['./auth-modal.component.scss'],
})
export class AuthModalComponent implements OnInit, AfterViewInit {
  showModal = false;
  public showRegister: boolean = false;
  public showLogin: boolean = true;
  public showGameMessage: boolean = false;
  public authExtraParams = {};
  public authUser: AuthUser = null;
  public authParams = {};

  @Input() set mode(m: string) {
    if (m == 'login') {
      this.showRegister = false;
      this.showLogin = true;
    } else if (m == 'register') {
      this.showRegister = true;
      this.showLogin = false;
    }
  }
  @Input() userGame: UserGame = null;

  @Output() afterAuth: EventEmitter<any> = new EventEmitter();
  @Output() onHideModal: EventEmitter<any> = new EventEmitter();

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private dataService: DataService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.auth.currentAuthUser.subscribe((user) => {
      this.authUser = user;
    });

    console.log('login dlg userGame=', this.userGame);

    if (this.userGame && this.userGame.game_id) {
      this.authExtraParams = {
        user_game: this.userGame,
      };
    }
  }

  ngAfterViewInit() {}

  // toggleModal(game=null) {
  //   // tempPlayer
  //   const tempPlayer = this.auth.getTempPlayer();
  //   if(tempPlayer && tempPlayer.userGame) {
  //     this.authExtraParams = {
  //       user_game: tempPlayer.userGame
  //     };
  //   }

  //   this.showModal = !this.showModal;
  //   if (this.showModal) {
  //     document.body.classList.add('modal-open');
  //   } else {
  //     document.body.classList.remove('modal-open');
  //   }
  // }

  hideModal(mode = 'current-game', game = null) {
    this.showModal = false;
    return this.modalCtrl.dismiss(game, mode);
  }

  onGoToRegister(event) {
    this.showRegister = true;
    this.showLogin = false;
  }

  onGoToLogin(event) {
    this.showRegister = false;
    this.showLogin = true;
  }

  afterLogin(event, authMode: string) {
    this.showLogin = false;
    this.showRegister = false;
    if (event && event.success && event.params) {
      this.authParams = event.params;
      if (this.authUser && this.authUser.similar_game) {
        if (this.authUser.confirm_s_game) {
          // show message
          this.showGameMessage = true;
          this.afterAuth.emit({
            similar_game_id: this.authUser.similar_game,
            authMode: authMode,
            authParameters: this.authExtraParams,
          });
        } else {
          this.afterAuth.emit({
            similar_game_id: this.authUser.similar_game,
            authMode: authMode,
            authParameters: this.authExtraParams,
          });
          this.hideModal();
        }
      } else {
        this.hideModal();
        this.afterAuth.emit({
          authMode: authMode,
          authParameters: this.authExtraParams,
        });
      }
    } else {
      this.afterAuth.emit({
        authMode: authMode,
        authParameters: this.authExtraParams,
      });
    }
  }

  continue(game) {
    switch (game) {
      case 'similar-game':
        this.hideModal('similar-game');
        break;
      case 'current-game':
        const storageUserGame = this.authParams['user_game'] ?? null;
        if (storageUserGame) {
          // overwrite the existing game(= the user game with the same game_id, category_id, difficulty) with the game from storage
          // fields updated: avatar, level, lives
          this.updateUserGame({
            id: this.authUser.similar_game,
            avatar_id: storageUserGame.avatar_id,
            level: storageUserGame.level,
            lives: storageUserGame.lives,
            game_id: storageUserGame.game_id,
            category_id: storageUserGame.category_id,
            difficulty: storageUserGame.difficulty,
          }).then((uGame) => {
            this.hideModal('current-game', uGame);
          });
        }
        break;
      case 'new-game':
        console.log('NNNNEW GAME');
        this.router.navigate(['/quizz/avatars']);
        break;
    }
  }

  updateUserGame(data) {
    return new Promise((resolve, reject) => {
      this.dataService
        .putAsPromise(`rs/usergame/${data.id}`, data)
        .then((res) => {
          if (res && res.data) {
            resolve(res.data);
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }
}
