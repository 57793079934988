<ion-content class="cms-background cms-type-{{content?.type}}" >
  <ion-fab class="cms-fab cms-fab-menu" slot="fixed" vertical="top" horizontal="start">
    <ion-fab-button (click)="cms.showSideMenu()">
      <ion-icon name="menu-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <ion-fab class="cms-fab cms-fab-back" slot="fixed" vertical="top" horizontal="end">
    <ion-fab-button (click)="cms.back()">
      <ion-icon name="chevron-back-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <cms-loader
    size="small"
    *ngIf="!ready"
  ></cms-loader>
  <div class="content-wrapper" *ngIf="ready">
    <cms-cached-image 
      class="img-header"
      *ngIf="content?.media_url"
      [src]="img_path + '/' + content?.media_url"
    ></cms-cached-image>

    <h1 class="content-title">{{ content?.title }}</h1>

    <ng-container *ngIf="content?.type === 'event'">
      <div class="event-wrapper">
        <div *ngIf="!date_to" class="on-one-day">
          <ion-icon name="calendar-clear-outline"></ion-icon>
          <div class="dates">
            <span class="date-from" *ngIf="date_format === 'date'">{{
              date_from | amLocale : locale | amDateFormat : "dddd D MMMM"
            }}</span>
            <ng-container *ngIf="date_format === 'datetime'">
              <span class="date-from">{{
                date_from | amLocale : locale | amDateFormat : "dddd D MMMM"
              }}</span>
              <span class="until">{{ "EVENTS.DATE_AT" | translate }}</span>
              <span class="date-to">{{
                date_from | amLocale : locale | amDateFormat : "HH:mm"
              }}</span>
            </ng-container>
          </div>
        </div>
        <div *ngIf="date_to" class="on-two-days">
          <ion-icon name="calendar-clear-outline"></ion-icon>
          <div class="dates">
            <span class="date-from">
              {{
                date_from | amLocale : locale | amDateFormat : "dddd D MMMM"
              }}</span
            >
            <span class="until">{{ "EVENTS.DATE_UNTIL" | translate }}</span>
            <span class="date-to">{{
              date_to | amLocale : locale | amDateFormat : "dddd D MMMM"
            }}</span>
          </div>
        </div>
        <div class="under-date">
          <div *ngIf="content?.location" class="location">
            <ion-icon name="location-outline"></ion-icon
            ><span>{{ content?.location }}</span>
          </div>

          <div class="add-agenda hand" (click)="addToAgenda()">
            <ion-icon name="add-circle-outline"></ion-icon>
            <span>{{ "EVENTS.ADD_TO_AGENDA" | translate }}</span>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngFor="let block of content?.blocks">
      <cms-block-text
        *ngIf="block?.type === 'text'"
        [block]="block"
      ></cms-block-text>

      <cms-block-image
        *ngIf="block?.type === 'image'"
        [block]="block"
      ></cms-block-image>

      <cms-block-gallery
        *ngIf="block?.type === 'gallery'"
        [block]="block"
      ></cms-block-gallery>

      <cms-block-video
        *ngIf="block?.type === 'video'"
        [block]="block"
      ></cms-block-video>

      <cms-block-content-gallery
        *ngIf="block?.type === 'content-gallery'"
        [block]="block"
      ></cms-block-content-gallery>
    </ng-container>
  </div>
  <cms-page-footer src="/assets/images/footer_small.jpg"></cms-page-footer>

  <octopus-menu></octopus-menu>

</ion-content>
<ion-footer *ngIf="content?.cta_label">
  <ion-button expand="full" (click)="clickCTA()">{{
    content?.cta_label
  }}</ion-button>
</ion-footer>
