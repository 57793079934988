import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  Input,
  AfterViewInit,
} from '@angular/core';
import { Platform } from '@ionic/angular';


@Component({
  selector: 'rs-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit, AfterViewInit {
  @ViewChild('video', { static: true }) video: ElementRef = new ElementRef(
    document.createElement('video')
  );

  @Input() avatar: string = 'bear';
  @Input() mode: string = 'game'; // game|wheel
  @Input() title: string = null;
  @Input() color: string = '#758b45';
  @Input() textColor: string = 'white';
  @Input() idleLoop: number = 3;
  @Input() startAfter: number = null;
  @Input() sleep: boolean = true;
  @Input() locked: boolean = false;
  @Input() size: string = 'fullwidth'; // fullwidth|vignette
  @Input() signLine1: string = null;
  @Input() signLine2: string = null;
  @Input() lives: number = null;
  @Input() waveOnClick: boolean = null;
  @Input() ready: boolean = true;

  @Output() onClickAvatar: EventEmitter<any> = new EventEmitter();
  
  
  
  _timer = null;
  cnt_idle = 0;

  protected sequences = {
    bear: {
      idle: { start: 0, duration: 6 },
      happy: { start: 7, duration: 3.0 },
      sad: { start: 11, duration: 3.24 },
      sleepy: { start: 15, duration: 6.24 },
      life: { start: 22, duration: 3.05 },
      lost: { start: 26, duration: 3.29 },
      dizzy: { start: 30, duration: 6.24 },
      wave: { start: 37, duration: 4 },
    },
    lynx: {
      idle: { start: 0, duration: 6 },
      happy: { start: 10, duration: 3.05 },
      sad: { start: 20, duration: 3.05 },
      sleepy: { start: 30, duration: 3.05 },
      life: { start: 40, duration: 3.05 },
      lost: { start: 50, duration: 3.0 },
      dizzy: { start: 0, duration: 6.0 },
      wave: { start: 37, duration: 4 },
    },
    badger: {
      idle: { start: 0, duration: 6 },
      happy: { start: 10, duration: 3.05 },
      sad: { start: 20, duration: 3.05 },
      sleepy: { start: 30, duration: 3.05 },
      life: { start: 40, duration: 3.05 },
      lost: { start: 50, duration: 3.0 },
      dizzy: { start: 0, duration: 6.0 },
      wave: { start: 37, duration: 4 },
    },
    owl: {
      idle: { start: 0, duration: 6 },
      happy: { start: 10, duration: 3.05 },
      sad: { start: 20, duration: 3.05 },
      sleepy: { start: 30, duration: 3.05 },
      life: { start: 40, duration: 3.05 },
      lost: { start: 50, duration: 3.0 },
      dizzy: { start: 0, duration: 6.0 },
      wave: { start: 37, duration: 4 },
    },
  };

  constructor(
    private platform: Platform
  ) {}

  ngOnInit() {

    this.platform.resume.subscribe(() => {
      console.log('App resumed')
      if (this.startAfter) {
        setTimeout(() => {
          this.playVideo();
        }, this.startAfter);
      } else {
        this.playVideo();
      }
    });


  }

  ngAfterViewInit() {
    if (this.startAfter) {
      setTimeout(() => {
        this.playVideo();
      }, this.startAfter);
    } else {
      this.playVideo();
    }
  }

  // play then stop sequence
  playSequence(start, duration) {
    return new Promise((resolve, reject) => {
      const video = this.video.nativeElement;
      video.currentTime = start;
      video.muted = true;
      video.setAttribute('playsinline', '');

      video.play();
      this._timer = setTimeout(() => {
        video.pause();
        resolve(true);
      }, duration * 1000);
    });
  }

  // stop video
  stopVideo() {
    if (this._timer && !this.locked) {
      clearTimeout(this._timer);
      this.video.nativeElement.pause();
    }
  }

  // play video
  public playVideo(emotion = 'idle') {

    //console.log('playVideo', emotion)
    if (this.sequences[this.avatar] && this.sequences[this.avatar][emotion] && !this.locked) {
      const sequence = this.sequences[this.avatar][emotion];

      this.stopVideo();

      switch (emotion) {
        case 'idle':
          this.cnt_idle++;

          this.playSequence(sequence.start, sequence.duration).then(() => {
            if (this.cnt_idle == this.idleLoop) {
              this.cnt_idle = 0;
              if (this.sleep) {
                // we go to sleep
                this.playVideo('sleepy');
              } else {
                // we loop
                this.playVideo('idle');
              }
            } else {
              this.playVideo('idle');
            }
          });

          break;
        default:
          this.playSequence(sequence.start, sequence.duration).then(() => {
            this.playVideo('idle');
          });
      }
    }
  }

  resetIdle() {
    this.cnt_idle = 0;
  }

  clickAvatar() {
    console.log('clickAvatar')
    if (this.waveOnClick && !this.locked) {
      this.playVideo('wave');
    } 
    this.onClickAvatar.emit(this);
  }
}
