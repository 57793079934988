import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringLimit'
})
export class StringLimitPipe implements PipeTransform {

  transform(value: string, limit: number): string {
    if (value && value.length > limit) {
      return value.substr(0, limit); // + '...';
    }
    return value;
  }

}