<ion-content class="cms-background">
  <ion-fab class="cms-fab" slot="fixed" vertical="top" horizontal="end">
    <ion-fab-button (click)="cms.back()">
      <ion-icon name="chevron-back-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <ion-fab class="cms-fab" slot="fixed" vertical="top" horizontal="start">
    <ion-fab-button (click)="cms.showSideMenu()">
      <ion-icon name="menu-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <div *ngIf="ready" class="nav-wrapper">
    <div class="grid">
      <div
        class="nav-item col-{{ header_item?.size }} {{
          header_item?.css
        }} first-navitem"
      >
        <cms-cached-image
          mode="div"
          class="cell-wrapper"
          [src]="nav_path + '/' + header_item.media_url"
          [class.hand]="header_item.url || header_item.content_id"
        >
          <div *ngIf="header_item?.loading" class="loader">
            <cms-loader size="small"></cms-loader>
          </div>

          <div class="nav-title">
            <span>{{ header_item.title }}</span>
          </div>
        </cms-cached-image>
      </div>

      <ion-list class="nav-item col-12 notifications-list">
        <cms-loader size="small" *ngIf="!ready || !notif_ready"></cms-loader>
        <ion-item-sliding *ngFor="let item of notifications" #slidingItem>
          <ion-item [class.unread]="item.unread">
            <ion-icon
              *ngIf="item.unread"
              class="unread"
              [name]="
                item.showDescription
                  ? 'chevron-up-circle'
                  : 'chevron-down-circle'
              "
              slot="end"
              (click)="toggleDescription(item)"
            ></ion-icon>

            <ion-icon
              *ngIf="!item.unread"
              [name]="
                item.showDescription
                  ? 'chevron-up-circle-outline'
                  : 'chevron-down-circle-outline'
              "
              slot="end"
              (click)="toggleDescription(item)"
            ></ion-icon>
            <ion-label (click)="toggleDescription(item)">
              <p class="notif-date">
                {{
                  item.date_notification
                    | amLocale : locale
                    | amDateFormat : "dddd D MMMM"
                }}
              </p>
              <h2>{{ item.title }}</h2>
              <ng-container *ngIf="item.showDescription">
                <p
                  class="notif-description"
                  [innerHTML]="item.short_description | nl2br"
                ></p>
                <ion-button
                  fill="clear"
                  expand="block"
                  *ngIf="item.event_id || item.content_id || item.url"
                  (click)="clickOnItem($event, item)"
                  >{{ "BUTTONS.BT_READ_MORE" | translate }}</ion-button
                >
              </ng-container>
            </ion-label>
          </ion-item>
          <ion-item-options>
            <ion-item-option
              color="primary"
              (click)="removeNotification($event, item, slidingItem)"
              ><ion-icon
                class="option-icon"
                slot="icon-only"
                name="trash"
              ></ion-icon
            ></ion-item-option>
          </ion-item-options>
        </ion-item-sliding>
      </ion-list>
    </div>
  </div>
  <octopus-menu></octopus-menu>
</ion-content>
