import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NavItem } from 'src/app/models/NavItem';
import { CmsService } from 'src/app/services/cms.service';
import { environment } from 'src/environments/environment';
import { Keyboard } from '@capacitor/keyboard';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss'],
})
export class SearchPageComponent implements OnInit {
  public ready: boolean = false;
  public nav_ready: boolean = false;
  public search_results: any[] = null;
  public nav_path: string = environment.fileserver.images;
  public menu_search: NavItem[] = null;
  public searching = false;
  public header_item: NavItem = null;
  public search_term: string = null;
  public keyboard_visible: boolean = false;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    public cms: CmsService,
    private zone: NgZone,
    private analytics: AnalyticsService

  ) {}

  ngOnInit() {
    this.ready = false;
    this.cms.getMenu('search-page').then((menu) => {
      this.header_item = menu[0];
      this.ready = true;

      this.cms.getMenu('search-menu').then((menu: any[]) => {
        this.menu_search = menu;
        this.nav_ready = true;
      });
    });

    Keyboard.addListener('keyboardWillShow', () => {
      this.keyboard_visible = true;
      this.zone.run(() => {});
    });
    Keyboard.addListener('keyboardDidHide', () => {
      this.keyboard_visible = false;
      this.zone.run(() => {});
    });
  }

  ngOnDestroy() {
    Keyboard.removeAllListeners();
  }

  ionViewDidEnter() {}

  goToMenu() {}

  changeSearch(event) {
    this.searching = true;

    console.log('changeSearch', event.detail.value, event);
    this.search_term = event.detail.value;
    setTimeout(async () => {
      await Keyboard.hide();
      this.zone.run(() => {});
    }, 100);

    if (event.detail.value && event.detail.value.length > 2) {
      this.cms.search(event.detail.value.trim()).then((res: any[]) => {
        if (res && res.length > 0) {
          this.search_results = this.prepareMenu(res);
          this.searching = false;
        } else {
          this.search_results = [];
          this.searching = false;
        }
        this.analytics.logEvent('search',{search_term:event.detail.value.trim()})
      });
    } else {
      this.search_results = null;
      this.searching = false;

      this.zone.run(() => {});
    }
  }
  clearchSearch(event) {
    console.log('clearchSearch', event);
  }

  prepareMenu(menu) {
    const menu_items = [];
    let psize = 0;
    for (let m of menu) {
      m.margin_top = true;
      m.size = 12;

      switch (m.size) {
        case 6:
          if (psize == 6) {
            m.margin_left = true;
          } else {
            m.margin_right = true;
          }
          break;
        case 12:
        default:
          if (psize === 0) {
            m.margin_top = false;
          }
      }

      menu_items.push(m);
      psize = m.size;
    }
    return menu_items;
  }

  clickOnItem(event, item) {
    if (item.event_id) {
      // regular url
      let url = `/event/${item.event_id}`;
      this.cms.goTo(url);
    } else if (item.permaling) {
      // regular url
      this.cms.goTo(item.permalink);
    } else if (item.id) {
      // regular url
      let url = `/content/${item.id}`;
      this.cms.goTo(url);
    }
  }

  async onMenuClick(item) {
    if (item.url) {
      if (item.is_external) {
        // external link
        this.cms.openLink(item.url);
        return;
      }
      this.cms.goTo(item.url);
    } else if (item.content_id) {
      // regular url
      let url = `/content/${item.content_id}`;
      this.cms.goTo(url);
    }
  }
}
