<div class="menu-wrapper" [class.blur]="blurMenu" *ngIf="menu?.length">
  <div class="main hand" [class.active]="expandMenu" (click)="ToggleMenu()">
    <div
      *ngIf="showNotifications && cms.unread_notifications"
      class="notification"
    ></div>
  </div>

  <div
    *ngFor="let item of menu; let idx = index"
    (click)="goTo(item)"
    [class.active]="expandMenu"
    class="item item_{{ idx }} hand"
  >
    <ion-icon [name]="item?.media_url"></ion-icon>
    <div
      *ngIf="
        showNotifications &&
        cms.unread_notifications &&
        item.url === '/notifications'"
      class="notification"
    ></div>
  </div>
</div>
