<div *ngIf="block?.data?.show_title" class="cms-title cms-block-padding">
  {{ block?.title }}
</div>

<ng-container *ngIf="block?.data?.player === 'vimeo'">
  <div class="video-wrapper {{ block?.data?.player }}">
    <div class="embed-container vimeo-player" #vimeoVideo></div>

    <div class="overlay" (click)="toggleVideo(!videoPlaying)">
      <ion-icon
        *ngIf="!videoPlaying"
        name="play-circle"
        (click)="toggleVideo()"
      ></ion-icon>
      <!-- <ion-icon
      class="expand-bt"
      name="expand"
      (click)="fullscreenVideo($event)"
    ></ion-icon> -->
    </div>
  </div>
</ng-container>

<ng-container *ngIf="block?.data?.player === 'youtube'" >
  <div class="video-wrapper {{ block?.data?.player }}" #wrapperVideo [style.height.px]="height">
    <!-- <youtube-player videoId="PRQCAL_RMVo"></youtube-player> -->
    <youtube-player
      #youtubeVideo
      *ngIf="width && height"
      [videoId]="videoId"
      [width]="width"
      [height]="height"
    ></youtube-player>
  </div>
</ng-container>
