import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { CmsService } from 'src/app/services/cms.service';

@Component({
  selector: 'octopus-menu',
  templateUrl: './octopus-menu.component.html',
  styleUrls: ['./octopus-menu.component.scss'],
})
export class OctopusMenuComponent implements OnInit {
  public expandMenu = false;
  public blurMenu = false;
  public menu = null;

  @Input() showNotifications : boolean = true;

  constructor(
    public cms: CmsService
  ) {
    this.cms.getMenu('octopus-menu').then((menu) => {
      this.menu = menu;
    });
  }

  ngOnInit() {}

  goTo(item) {
    this.ToggleMenu();
    setTimeout(()=>{
      this.cms.goTo(item.url)
    },400)
    
  }

  ToggleMenu() {
    this.expandMenu = !this.expandMenu;
  }

}
