<div *ngIf="block?.data?.show_title" class="cms-title cms-block-padding">
  {{ block?.title }}
</div>
<swiper-container  
[modules]="swiperModules" 
[pager]="block?.data.pager" 
[slidesPerView]="1.2"  
[options]="slideOpts"
>
  <swiper-slide class="slide hand" *ngFor="let media of block?.data?.media; let idx=index;">
      <cms-cached-image  mode="img" class="slide-img" [src]="img_path + '/' + media.url" (click)="fullscreenImage(idx)"></cms-cached-image>
  </swiper-slide>
</swiper-container>
