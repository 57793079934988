import { ContentBlock } from './ContentBlock';

export class Content {
  id: number | null = null;
  lang: string = null;
  permalink: string | null = null;
  type: string | null = null;
  is_published: boolean | null = null;
  title: string | null = null;
  media_url: string | null = null;
  cta_label: string | null = null;
  cta_url: string | null = null;
  cta_target: string | null = null;
  blocks: ContentBlock[] = null;
  hashtags: any[] = null;

  constructor(data, do_import=true) {
    if (do_import) {
      this.import(data);
    }
  }

  protected import(data) {
   
    for (var key in data) {
      if (this.hasOwnProperty(key)) {
        
          switch (key) {
            case 'blocks':
              if (!this.blocks) {
                this.blocks = [];
              }
              data[key].forEach((b) => {
                this.blocks.push(new ContentBlock(b));
              });
  
              break;
            default:
              this[key] = data[key];
          }
      }
    }
  }

  exists() {
    return !!this.id;
  }
}
