<div *ngIf="block?.data?.show_title" class="cms-title cms-block-padding">
  {{ block?.title }}
</div>
<swiper-container  [modules]="swiperModules" [slidesPerView]="1.5" [pager]="block?.data.pager" [options]="slideOpts">
  <swiper-slide class="slide hand" *ngFor="let content of block?.data?.contents">
    <div class="slide-wrapper" (click)="goTo($event, content)">
      <div class="img-wrapper">
      <cms-cached-image class="slide-img" [src]="img_path + '/' + content?.media_url"></cms-cached-image>
      </div>
      <span class="title">{{ content?.title }}</span>
      <!-- <span class="subtitle">{{ content?.type }}</span> -->
    </div>
  </swiper-slide>
</swiper-container>
