<div class="video-wrapper {{ size }}">
  <div
    class="video-container"
    [style.background-image]="
      'url(/assets/quizz/avatars/' + avatar + '/background.jpg)'
    "
  >
    <video
      [class.locked]="locked"
      #video
      class="video"
      poster="/assets/quizz/avatars/{{ avatar }}/background.jpg"
      playsInline="true"
      preload="auto"
    >
      <source
        src="/assets/quizz/avatars/{{ avatar }}/avatar_480.mp4"
        type="video/mp4"
      />
    </video>
    <!-- <img *ngIf="locked" src="/assets/quizz/avatars/{{ avatar }}/background.jpg"> -->
    <div *ngIf="ready && size === 'fullwidth' && !locked" class="overlay">
      <div *ngIf="signLine1 || signLine2" class="sign {{ avatar }}">
        <div class="text">
          <span *ngIf="signLine1">{{ signLine1 }}</span>
          <span *ngIf="signLine2">{{ signLine2 }}</span>
        </div>
      </div>
      <div class="top-avatar" (click)="clickAvatar()"></div>
      <div *ngIf="lives !== null" class="lives {{ avatar }}">
        <div class="heart">
          <span>{{ lives }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="size === 'vignette'" class="overlay">
      <div class="top-avatar" (click)="clickAvatar()"></div>
    </div>
  </div>
</div>
