import { Component, Input, OnInit } from '@angular/core';
import { CmsService } from 'src/app/services/cms.service';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cms-cached-image',
  templateUrl: './cached-image.component.html',
  styleUrls: ['./cached-image.component.scss'],
})
export class CachedImageComponent implements OnInit {
  private cache_folder = environment['cache_folder'] || null;

  public _src: string = '';
  @Input() mode: string = 'img';

  @Input()
  set src(imageUrl: string) {
    if (!this.cache_folder) {
      // cache is not enabled
      console.log('No cache:', imageUrl);
      this._src = imageUrl;
      return;
    }

    const imageName = imageUrl.split('/').pop();
    const fileType = imageUrl.split('.').pop();

    Filesystem.readFile({
      directory: Directory.Cache,
      path: `${this.cache_folder}/${imageName}`,
    })
      .then((readFile) => {
        // file already in cache
        //console.log('LOCAL FILE:, readFile')
        this._src = `data:image/${fileType};base64,${readFile.data}`;
      })
      .catch(async (e) => {
        // write the file
        await this.storeImage(imageUrl, imageName);
        Filesystem.readFile({
          directory: Directory.Cache,
          path: `${this.cache_folder}/${imageName}`,
        }).then((readFile) => {
          // file already in cache
          this._src = `data:image/${fileType};base64,${readFile.data}`;
        });
      });
  }

  constructor(public cms: CmsService) {}

  ngOnInit() {}

  blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(blob);
    });
  };

  async storeImage(url, path) {
    const response = await fetch(url);
    const blob = await response.blob();
    // convert to b64
    const base64data = (await this.blobToBase64(blob)) as string;

    const savedFile = await Filesystem.writeFile({
      path: `${this.cache_folder}/${path}`,
      data: base64data,
      directory: Directory.Cache,
    });
    return savedFile;
  }
}
