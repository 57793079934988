import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { SharedModule } from './modules/shared/shared.module';
import { AppLayoutComponent } from './components/layouts/app-layout/app-layout.component';
import { CmsdModule } from './modules/shared/cms.module';
import { AudioService } from './services/audio.service';
import { ToastService } from './services/toast.service';
import { NotificationService } from './services/notifications.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { QuizzService } from './modules/quizz/services/quizz.service';
import { AppLayoutDesktopComponent } from './components/layouts/app-layout-desktop/app-layout-desktop.component';
import { AnalyticsService } from './services/analytics.service';

export function createTranslateLoader(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/i18n/', suffix: '.json' },
    { prefix: './assets/i18n/', suffix: '.static.json' },
  ]);
}

@NgModule({
  declarations: [AppComponent, AppLayoutComponent, AppLayoutDesktopComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    CmsdModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register('combined-sw.js', {
      enabled: environment.pwa === true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    TranslateService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    AudioService,
    ToastService,
    NotificationService,
    QuizzService,
    AnalyticsService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private translate: TranslateService) {
    const langs = ['ro', 'en'];

    translate.addLangs(langs);
    translate.setDefaultLang('ro');
    let lang = localStorage.getItem('locale');

    translate.use(lang || langs[0]);
  }
}
