import * as _ from 'lodash';
import * as moment from 'moment';
import { Avatar } from './Avatar';


export interface Level {
  level: number;
  right: number;
  wrong: number;
} 

export class UserGame {
  id: number | null = null;
  user_id: number | null = null;
  game_id: number | null = null;
  avatar_id: number | null = null;
  category_id: number | null = null;
  difficulty: string | null = null;
  mode: string | null = null;
  level: number | null = null;
  lives: number = 3;
  levels: Level[] = null;

  avatar: Avatar | null = null;
  game: {} | null = null;
  category: {} | null = null;
  updated_at: string = null;
  
  resurrected_at: string = null;

  max_level: number | null = null; // saved level

  constructor(id?: number | Object ) {
    if (typeof id == 'object') {
      this.import(id);
    } else {
      this.id = id as number | null;
    }
    if (!this.updated_at) {
      this.updated_at = moment().format('Y-MM-DD');
    }
  }

  exists() {
    return !!this.id;
  }

  import(data: any) {
    for (var key in data) {
      if (this.hasOwnProperty(key)) {
        switch (key) {
          case "avatar":
            this.avatar = new Avatar(data[key]);
            this.avatar_id = this.avatar.id;
          break;
          default:
            this[key as keyof this] = data[key];
        }
      }
    }
  }

  getLevel() {
    return this.level;
  }

  getLevelData(level=null) {
    if (level===null) {
      level=this.level;
    }
    return this.levels[level];
  }


  getDifficulty() {
    return this.difficulty;
  }

  getCategory() {
    return this.category
  }

  getGameCode() {
    return this.game ? this.game['code'] : null;
  }

  getLives() {
    return this.lives;
  }
}

