<ion-app>
  <ng-container *ngIf="!auth?.isLoading && cms.isNetworkActive">
    <app-layout *ngIf="!isDesktop"></app-layout>
    <app-layout-desktop *ngIf="isDesktop"></app-layout-desktop>
    

  </ng-container>
  <div class="no-network" *ngIf="!cms.isNetworkActive">
    <cms-loader      
      size="big"
    ></cms-loader>
    <span>{{ "APP.NO_NETWORK" | translate }}</span>
  </div>
</ion-app>
