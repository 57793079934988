import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CmsService } from 'src/app/services/cms.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-events-page',
  templateUrl: './events-page.component.html',
  styleUrls: ['./events-page.component.scss'],
})
export class EventsPageComponent implements OnInit {
  public ready: boolean = false;
  public nav_path: string = environment.fileserver.images;
  public locale: string = 'en';
  public events = null;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    public cms: CmsService,
    private analytics: AnalyticsService

  ) {
    this.locale = this.translate.currentLang;
  }

  ngOnInit() {
    this.cms.getEvents().then((events) => {
      console.log('Events', events);
      this.events = this.prepareEvents(events);
      this.ready = true;
      this.analytics.setScreenName('events', 'Events');

    });
  }

  prepareEvents(events) {
    const menu_items = [];
    let psize = 0;
    for (let m of events) {
      m.margin_top = true;

      switch (m.size) {
        case 6:
          if (psize == 6) {
            m.margin_left = true;
          } else {
            m.margin_right = true;
          }
          break;
        case 12:
        default:
          if (psize === 0) {
            m.margin_top = false;
          }
      }

      const from = moment(m.date_from);
      const to = moment(m.date_to);

      const from_fullday = from.format('HH:mm:ss') == '00:00:00';
      const to_fullday = to.format('HH:mm:ss') == '00:00:00';

      if (from_fullday) {
        // full days
        m.date_from = from.format('YYYY-MM-DD');
        m.date_format = 'date';
        
        if (!from.isSame(to, 'day')) {
          // several days
          m.date_to = to.format('YYYY-MM-DD');
        } else {
          m.date_to = null;
        }
      } else {
        // date time
        m.date_format = 'datetime';
        if (from.isSame(to, 'day')) {
          m.date_to = null;
        }
      }

      menu_items.push(m);
      psize = m.size;
    }
    console.log(menu_items)
    return menu_items;
  }

 


  clickOnItem(event, item) {
    console.log('clickOnItem', item);
    // regular url
    let url = `/event/${item.id}`;
    this.cms.goTo(url);
  }

  goToMenu() {}
}
