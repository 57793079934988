import { Content } from './Content';

export class Event extends Content {
  public date_from: string = null;
  public date_to: string = null;
  public location: string = null;

  constructor(data,do_import=true) {
    super(data, false);
    if (do_import) {
      this.import(data);
    }
  }

  override import(data) {
    super.import(data);

    for (var key in data) {
      switch (key) {
        case 'date_from':
        case 'date_to':
        case 'location':
          this[key] = data[key];
          
          break;
        default:
      }
    }
    
    

  }

}
