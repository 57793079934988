
export class NavItem {
  id: number | null = null;
  lang: string = null;
  content_id: number | null = null;
  is_external: boolean | null = null;
  is_separator: boolean | null = null;
  media_url: string | null = null;
  order: number | null = null;
  menu: string | null = null;
  title: string | null = null;
  perms: string[] | null = null;
  size: number | null = null;
  url: string = null;
  css: string = null;
  loading: boolean | null = null;
  
  constructor(data) {
    if (data) {
      this.import(data);
    }
  }

  import(data) {
    for (var key in data) {
      if (this.hasOwnProperty(key)) {
        switch (key) {
          
          default:
            this[key] = data[key];
        }
      }
    }
  }

  exists() {
    return !!this.id;
  }
}
